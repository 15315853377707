export const cn = {
    "How Others Can Find You": "别人如何找到你",
    "Schedules": "时间表",
    "Login": "登陆",
    "Start Your InstaPay Journey Today!": "今天就开始您的InstaPay之旅！",
    "Phone Number or Email": "手机号或电子邮件",
    "Login Using Social Media": "使用社交媒体登录",
    "Continue": "继续",
    "Don't Have an Account? Sign Up": "还没有账号？注册",
    "Sign Up": "注册",
    "Edit": "编辑",
    "Enter Your Password": "输入您的密码",
    "Forgot Password?": "忘记密码？",
    "Account Type": "账户类型",
    "Individual": "个人",
    "Business": "商业",
    "Already Have an Account? Sign In": "已经有账户了？登入",
    "Sing In": "登入",
    "Enter Your Personal Details": "输入您的个人资料",
    "Add Photo or Logo": "添加照片或标志",
    "Enter Your Name": "输入您的姓名",
    "Back": "返回",
    "Phone Number": "手机号码",
    "Email Address": "电子邮件地址",
    "Create a Strong Password": "创建一个强密码",
    "Password Requirements": "密码要求",
    "Must be between 9 and 64 characters": "必须在9到64个字符之间",
    "Include at least two of the following:": "至少包含以下两项：",
    "Uppercase character": "大写字母",
    "Lowercase character": "小写字母",
    "Number": "数字",
    "Special character": "特殊字符",
    "Confirm Password": "确认密码",
    "Finalize Your Process": "完成您的流程",
    "Referral Code (Optional)": "推荐码（可选）",
    "It will autofill if you're signing up with an invitation link.": "如果您通过邀请链接注册，它会自动填充。",
    "I agree with": "我同意",
    "Terms & Conditions": "条款和条件",
    "Privacy Policy": "隐私政策",
    "Create Your Account": "创建您的账户",
    "Enter Code": "输入代码",
    "A one-time verification code has been sent to your email address  and phone number.": "一次性验证代码已发送到您的电子邮件地址和手机号码。",
    "Resend available in ": "在",
    "seconds": "秒内重新发送可用",
    "Verify": "验证",
    "Verification Code Verified": "验证码已验证",
    "Please continue to the next step.": "请继续下一步。",
    "Finish": "完成",
    "Finishing": "完成中",
    "Company Name": "公司名称",
    "Enter Your Business Details": "输入您的商业详情",
    "Country ": "国家",
    "You cannot change the country once your account is verified.": "一旦您的账户验证后，您将无法更改国家。",
    "Learn more": "了解更多",
    "Optional": "可选",
    "Continuing": "继续",
    "Didn't receive the verification code?": "没有收到验证码？",
    "Resend Now": "现在重发",
    "Search Here": "在这里搜索",
    "Search for users, services, and more on InstaPay": "在InstaPay上搜索用户、服务等",
    "Search users here": "在这里搜索用户",
    "Earn money with our affiliate program!": "通过我们的联盟计划赚钱！",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "通过与您的追随者分享您的InstaPay联盟代码，生成被动收入。\n他们每进行一次交易，您都会赚取佣金，从而最大化您的在线影响力所带来的利润。",
    "Learn More": "了解更多",
    "Balance Amount": "余额金额",
    "You spent less compared to last month.": "您这个月的支出比上个月少。",
    "Referral Rewards": "推荐奖励",
    "Bitcoin": "比特币",
    "USDT": "USDT",
    "Ethereum": "以太坊",
    "Quick Transfer to Your Most Recent Contacts:": "快速转账至您最近的联系人：",
    "Number of Transactions": "交易次数",
    "Total Transaction Amount": "总交易金额",
    "Total Sent": "总发送",
    "Total Received": "总接收",
    "Add Funds": "添加资金",
    "Send Money": "发送钱款",
    "Request Money": "请求资金",
    "Send a Quote": "发送报价",
    "Pay With Crypto": "使用加密货币支付",
    "Payment Insights": "支付洞察",
    "Recent Transactions ": "最近交易",
    "Today": "今天",
    "My Wallets": "我的钱包",
    "Balance": "余额",
    "Wallet ID": "钱包ID",
    "Monthly limit": "每月限额",
    "Top Up": "充值",
    "Summary": "总结",
    "Settings": "设置",
    "More": "更多",
    "Upgrade to Business Account": "升级为商业账户",
    "Conversion": "转换",
    "Enter Amount": "输入金额",
    "Exchanged Amount": "兑换金额",
    "Convert": "兑换",
    "You Are Converting": "您正在转换",
    "From": "来自",
    "To": "到",
    "Exchange Rate": "汇率",
    "Fees": "费用",
    "You Will Receive": "您将收到",
    "Slide To Confirm": "滑动以确认",
    "Verification Code Confirmed - You'll be redirected to the next step": "验证码已确认 - 您将被重定向到下一步",
    "Moving to next step in": "即将进入下一步",
    "Thanks for using InstaPay": "感谢您使用InstaPay",
    "Your funds have been credited to your wallet.": "您的资金已存入您的钱包。",
    "Dashboard": "仪表板",
    "Accounts": "账户",
    "Transactions": "交易",
    "Other Transactions": "其他交易",
    "Payments": "支付",
    "Beneficiaries": "受益人",
    "Referrals": "推荐",
    "Quotations": "报价",
    "Pending": "待处理",
    "My QR Code Sticker": "我的二维码贴纸",
    "My Portfolio": "我的投资组合",
    "My Payment Address": "我的支付地址",
    "Analytics": "分析",
    "Profile": "个人资料",
    "Dark Mode": "暗黑模式",
    "Support": "支持",
    "Logout": "登出",
    "Powered By": "技术支持",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "瑞士金融科技公司",
    "MAIN MENU": "主菜单",
    "OTHERS": "其他",
    "Wallet": "钱包",
    "Default": "默认",
    "Breakdown": "细分",
    "Credit": "信用",
    "Debit": "借记",
    "Wallet Management": "钱包管理",
    "Top-up Your Wallet": "充值您的钱包",
    "Download Statement": "下载对账单",
    "Block the Wallet": "锁定钱包",
    "Wallet Status": "钱包状态",
    "-Select-File-Type-": "-选择文件类型-",
    "Download Now": "现在下载",
    "Downloading": "正在下载",
    "All": "全部",
    "Sent": "已发送",
    "Received": "已接收",
    "Requested": "已请求",
    "Quoted": "已报价",
    "Search Transactions": "搜索交易",
    "Date & Time": "日期与时间",
    "Type": "类型",
    "Transaction ID": "交易ID",
    "Recipient": "接收者",
    "Amount": "金额",
    "Status": "状态",
    "Payment Type": "支付类型",
    "Sent Payments": "已发送的支付",
    "Received Payments": "已接收的支付",
    "Date": "日期",
    "Cycles/Time": "周期/时间",
    "Payment As": "以支付方式",
    "Name": "姓名",
    "Cancel": "取消",
    "Subscriptions & Scheduled": "订阅与计划",
    "Select the option that matches your needs from the list below:": "从下面的列表中选择符合您需求的选项：",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "轻松地向国内外的朋友、家人或企业发送资金。从多种转账选项中选择，\n包括银行存款、移动钱包、现金提取、支付卡以及钱包到钱包的转账。\n享受InstaPay提供的便利和安全，实现无缝和即时的资金转账。",
    "International Transfer": "国际转账",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "轻松跨境发送资金。只需几次点击，就可以将资金国际转账给朋友、家人或企业。\n从包括银行账户、移动货币/移动钱包、现金提取地点或支付卡在内的多种支付渠道中选择，\n进行便捷且安全的交易。",
    "Select the country where you want to send the money!": "选择您想要发送资金的国家！",
    "Search Country": "搜索国家",
    "Trouble Sending Money?": "发送资金遇到困难？",
    "Confirm": "确认",
    "Select Payout Channel": "选择付款渠道",
    "Select your most convenient option to send the money.": "选择最便捷的方式发送资金。",
    "Select The Bank": "选择银行",
    "Search Bank": "搜索银行",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "直接将资金发送到收款人的银行账户。享受无缝转账至全球任何银行的便利和安全。",
    "Bank Transfer": "银行转账",
    "Enter the amount": "输入金额",
    "Tax": "税款",
    "Amount the recipient will receive": "收款人将收到的金额",
    "Select Remarks / Reason": "选择备注/原因",
    "Comments": "评论",
    "Attach Files": "附加文件",
    "Groups": "群组",
    "Manage Groups": "管理群组",
    "Add New Group": "新增群组",
    "Invite": "邀请某人",
    "Manage Beneficiaries": "管理收款人",
    "Recipients List": "收款人列表",
    "Search Contacts": "搜索联系人",
    "Confirm Your Payment": "确认您的支付",
    "Select Country": "选择国家",
    "Select the Recipient's Payment Channel": "选择收款人的支付渠道",
    "Change Selection": "更改选择",
    "You Are Transferring ": "您正在转账",
    "Oops! Something Went Wrong.": "哎呀！出了点问题。",
    "We're Sorry About That. ": "对此我们深感抱歉。",
    "Go back to Payments Page.": "返回支付页面。",
    "Wallet to Wallet Transfer": "钱包到钱包转账",
    "Request a customized price quote from service providers or vendors.": "向服务提供商或供应商请求定制价格报价。",
    "Enter the Wallet ID": "输入钱包ID",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "输入收款人的钱包ID、电子邮件或手机号码。",
    "Recipient not on InstaPay?": "收款人还不在InstaPay上？邀请他们。",
    "Invite Them": "邀请他们",
    "My Beneficiaries": "我的受益人",
    "Enter the Amount You Wish to Transfer.": "输入您希望转账的金额。",
    "Sending to ": "发送到",
    "You Have Selected Wallet to Wallet Transfer": "您已选择钱包到钱包转账",
    "Authenticate Your Payment.": "验证您的支付。",
    "You are transferring": "您正在转账",
    "Total ": "总计",
    "Where Is My Payment?": "我的付款在哪里？",
    "Share": "分享",
    "Your Payment Is Confirmed": "您的支付已确认",
    "QR Code Payment": "二维码支付",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "通过我们安全的钱包到钱包服务，即时向其他InstaPay用户转账。在几秒钟内轻松发送和接收资金。",
    "Scan QR with Your Camera or Upload from Your Device.": "使用您的相机扫描二维码或从您的设备上传。",
    "Upload from Your Device.": "从您的设备上传。",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "或者，使用InstaPay独特的支付地址（UPA）",
    "Enter InstaPay UPA": "输入InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "收款人不在InstaPay上？邀请他们",
    "Verify QR Code": "验证二维码",
    "Scan/Upload QR Code of the Recipient": "扫描/上传收款人的二维码",
    "Payment Confirmed": "支付已确认",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "向服务提供商或供应商请求定制价格报价，以获取您所需的服务或产品的准确成本估算。",
    "Choose The Beneficiary or Group": "选择受益人或群组",
    "Enter the Amount You Wish to Request.": "输入您希望请求的金额。",
    "You Are Requesting Money": "您正在请求资金",
    "You are requesting": "您正在请求",
    "As": "作为",
    "Conversion of Your Cryptocurrencies": "您的加密货币兑换",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "体验我们以用户为中心、安全高效的加密货币到法币兑换。",
    "Choose the Cryptocurrency You Intend to Liquidate.": "选择您打算变现的加密货币。",
    "Next": "下一步",
    "Enter the Crypto Wallet Address of the Recipient.": "输入收款人的加密钱包地址。",
    "Minimum Transactional Value: $100.": "最低交易价值：100美元。",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "注意：加密货币价格经常变动。请参考确认订单页面的最终报价。",
    "Select Network": "选择网络",
    "Send A Quote": "发送报价",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "向您的客户发送详细的价格报价。使用个性化报价专业展示您的服务、产品或优惠。",
    "Choose the Beneficiary": "选择受益人",
    "Allow Receiver to Bargain": "允许接收方讨价还价",
    "Enter the Title for Your Quotation.": "输入您报价的标题。",
    "Enter the Description for Your Quotation": "输入您报价的描述",
    "You are Quoting": "您正在报价",
    "Title": "标题",
    "Description": "描述",
    "Attachments": "附件",
    "Confirm Your Quotation": "确认您的报价",
    "Verify The Quotation": "验证报价",
    "Your quotation is confirmed": "您的报价已确认",
    "Request A Quote": "请求报价",
    "Coming Soon": "即将推出",
    "Add Beneficiary": "添加受益人",
    "Search for Beneficiaries": "搜索受益人",
    "Connected Accounts": "已连接账户",
    "Details": "详情",
    "First Name": "名字",
    "Last Name": "姓氏",
    "Country": "国家",
    "City": "城市",
    "Address Line": "地址行",
    "Mobile Number": "手机号码",
    "Relationship With Beneficiary": "与受益人的关系",
    "Bank Account": "银行账户",
    "Mobile Money/Mobile Wallet": "移动货币/移动钱包",
    "Payment Card": "支付卡",
    "Crypto Wallet": "加密钱包",
    "InstaPay Account": "InstaPay账户",
    "Cash Pickup": "现金提取",
    "IBAN / SWIFT Code": "IBAN或Swift/BIC代码",
    "Swift Code": "Swift代码",
    "Account Number": "账号",
    "Bank Name": "银行名称",
    "Branch Name": "分行名称",
    "Branch Street": "分行街道",
    "Province": "省份",
    "Postal Code": "邮政编码",
    "Mobile Money Provider": "移动货币提供商",
    "Wallet Name": "钱包名称",
    "Wallet Number": "钱包号码",
    "Card Holder Name": "持卡人姓名",
    "Card Number": "卡号",
    "Expiry Date": "到期日期",
    "Crypto Currency": "加密货币",
    "Wallet Address": "钱包地址",
    "Wallet Holder Name": "钱包持有人姓名",
    "Wallet Currency": "钱包货币",
    "Select Document Type": "选择文件类型",
    "Enter Document Number": "输入文件号码",
    "Add Individual Account": "添加个人账户",
    "Add Business Account": "添加商业账户",
    "Company Address": "公司地址",
    "Company Email": "公司电子邮件",
    "Company Phone No": "公司电话号码",
    "Total Referrals": "总推荐",
    "My Earnings": "我的收益",
    "Your Referral ID": "您的推荐ID",
    "Share Your Unique Referral Link": "分享您独特的推荐链接",
    "How do I Refer a Friend to InstaPay?": "我如何推荐朋友使用InstaPay？",
    "Step 01": "步骤01",
    "Share your InstaPay unique referral link with your friends.": "与您的朋友分享您独特的InstaPay推荐链接。",
    "Step 02": "步骤02",
    "Earn rewards when they transact $100 or more": "当他们交易100美元或以上时，您将获得奖励。",
    "Step 03": "步骤03",
    "Earn up to $0.06 for every transaction your followers make.": "您的追随者每进行一次交易，您最多可以赚取0.06美元。",
    "My Referrals": "我的推荐",
    "Referral Complete": "推荐完成",
    "Received Quotations": "收到的报价",
    "Sent Quotations": "发送的报价",
    "Search Pending Items": "搜索待处理项目",
    "Actions": "操作",
    "Received Requests": "收到的请求",
    "Sent Requests": "发送的请求",
    "No Actions": "无操作",
    "You Requested": "您请求的",
    "Via": "通过",
    "How was your experience?": "您的体验如何？",
    "Leave a Review": "留下评论",
    "Overall Ratings": "总体评价",
    "Visit Profile": "访问个人资料",
    "Ratings": "评分",
    "Reviews": "评论",
    "No reviews available": "暂无评论",
    "Select Your Currency": "选择您的货币",
    "Proceed": "继续",
    "Commision": "佣金",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "通过InstaPay二维码贴纸解锁无缝交易",
    "Effortless and Swift:": "轻松快捷：",
    "Instantly download your unique InstaPay QR code sticker.": "立即下载您独特的InstaPay二维码贴纸。",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "体验无忧无虑的无现金支付，无需复杂的支付终端等基础设施。",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "迅速收款 - 不再需要繁琐地输入信用卡或电话号码。",
    "Each payment includes customer information for your convenience.": "每笔支付都包括客户信息，方便您使用。",
    "Economically Smart:": "经济智能：",
    "Zero activation fees – start immediately without upfront costs.": "零激活费 - 立即开始，无需前期成本。",
    "Forget about fixed monthly charges; pay as you transact.": "忘记固定的月费；您的交易多少，就支付多少。",
    "Ditch the hardware; no terminal costs mean more savings for you.": "不需要硬件；无终端成本意味着您能节省更多。",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "享受低廉的交易费，每笔二维码支付仅需0.75%。",
    "We don't impose minimum commissions; you save more with each transaction.": "我们不强加最低佣金；每笔交易您都能节省更多。",
    "Experience the joy of instant payments, enhancing your cash flow.": "体验即时支付的快乐，增强您的现金流。",
    "Make Every Transaction Count with InstaPay": "让每笔交易都有价值，使用InstaPay",
    "Wallet QR": "钱包二维码",
    "Download QR": "下载二维码",
    "QR Code Status": "二维码状态",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "分享您的二维码或支付地址链接，即可立即在您的InstaPay钱包中接收支付。",
    "QR Code Title": "二维码标题",
    "View Transactions": "查看交易",
    "My QR Codes:": "我的二维码：",
    "Load More": "加载更多",
    "Benefits Of Portfolio": "投资组合的好处",
    "Benefits:": "优势：",
    "Enhanced Visibility:": "提升能见度：",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "提升您的公共形象。您的投资组合充当动态展示，可直接通过您的支付地址访问。",
    "Engage Your Audience:": "吸引您的观众：",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "通过您的创意或专业之旅与访问者建立联系。让您的作品代表您，与潜在客户或支持者建立更深入的互动。",
    "Integrated Social Proof:": "综合社交证明：",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "展示您的社交媒体影响力。访问者可以看到您的社交媒体影响范围，为您的投资组合增加可信度和背景。",
    "Seamless Transactions:": "无缝交易：",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "使访问者能够直接从您的公共页面请求或发起支付。您的投资组合不仅展示了您的作品，还便利了简单的财务互动。",
    "Personalized Storytelling:": "个性化叙述：",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "使用“关于我”部分分享您的故事、使命或愿景，与您的观众建立个人联系。",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "我的投资组合”不仅仅是一个画廊；它是一个在InstaPay上建立您品牌、吸引观众和简化财务互动的工具。",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "展示您的作品，扩大您的影响力。‘我的投资组合’是您在InstaPay上的数字画布。上传和展示各种内容 - 从迷人的图片和视频到信息丰富的PDF。\n无论您是艺术家、自由职业者还是企业家，此功能都让您以视觉吸引的方式展示您的作品或项目。",
    "Add/Manage Portfolio": "添加/管理投资组合",
    "Add New": "新增",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "通过您的InstaPay支付地址最大化您的社交媒体收益",
    "Make It Effortless for Your Audience to Support You:": "让您的观众轻松支持您：",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "轻松将您的InstaPay支付地址整合到您的社交媒体帖子中。",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "轻松复制并粘贴您的支付地址链接到您的每一个帖子、故事中，或在您的个人资料上显著位置。",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "适合影响者、艺术家、企业家或任何拥有渴望表达支持的社区的人。",
    "Instant Support, Endless Possibilities:": "即时支持，无限可能：",
    "Transform the way you monetize your social media content.": "改变您通过社交媒体内容赚钱的方式。",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "让您的追随者仅通过一次点击就能立即发送资金，直接从他们最喜爱的社交网络。",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "适合在直播期间实时支持、作为您创意内容的打赏系统，或为您的下一个大项目提供资金。",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "在何处放置您的InstaPay支付地址以产生最大影响：",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "社交媒体个人资料：将其固定在您的简介或关于部分，以保持持续可见。",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "帖子和公告：将其融入您的个别帖子中，尤其是那些突出您的作品或特定支持呼吁的帖子。",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "故事和短片：将其整合到您的互动故事或迷人短片中，以捕获即时贡献。",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "直播：在您的LIVE会话期间分享它，使观众能够实时贡献。",
    "Simple, Fast, and Secure:": "简单、快速、安全：",
    "No complex setup – get started in moments.": "无需复杂设置 - 立即开始。",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "享受InstaPay值得信赖的支付处理的安全和速度。",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "从任何地方接收来自任何人的支付，无需他们拥有InstaPay账户。",
    "Elevate Your Social Media Game with InstaPay Payment Address": "使用InstaPay支付地址提升您的社交媒体游戏。",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA（虚拟支付地址）",
    "Payment Address Title": "支付地址标题",
    "Currency": "货币",
    "Payment Address Description": "支付地址描述",
    "Update": "更新",
    "Updating": "正在更新",
    "Total Transaction": "总交易",
    "Last 30 days": "最近30天",
    "Last 1 month": "最近1个月",
    "Last 1 year": "最近1年",
    "Custom Date": "自定义日期",
    "Payment Methods": "支付方式",
    "Payment Types": "支付类型",
    "Requested Amount": "请求的金额",
    "Quoted Amount": "报价的金额",
    "About Me": "关于我",
    "Followers": "追随者",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "下载二维码",
    "Initiate A Payment": "发起支付",
    "Report this person": "举报此人",
    "Add now": "现在添加",
    "Recent Reviews": "最近的评论",
    "Reviews As Seller": "作为卖家的评论",
    "Reviews As Buyer": "作为买家的评论",
    "Select Language": "选择语言",
    "Profile Completion": "资料完成情况",
    "Profile Completed": "资料已完成",
    "Basic Info": "基本信息",
    "Username": "用户名",
    "Gender": "性别",
    "Male": "男",
    "Female": "女",
    "-Select-Gender-": "-选择性别-",
    "Date Of Birth": "出生日期",
    "Address": "地址",
    "Edit Profile": "编辑资料",
    "Update Profile": "更新资料",
    "Updating Profile": "正在更新资料",
    "Complete Now": "立即完成",
    "Password": "密码",
    "Change Password": "更改密码",
    "New Password": "新密码",
    "Re-enter New Password": "重新输入新密码",
    "Please follow this guide for a strong password": "请遵循此指南设置强密码",
    "Include at least one special character.": "至少包含一个特殊字符。",
    "Minimum of 8 characters.": "最少8个字符。",
    "Include at least one number": "至少包含一个数字",
    "Change it often for enhanced security.": "为了增强安全性，经常更换。",
    "Security Questions": "安全问题",
    "Security Question": "安全问题",
    "Select your question": "选择您的问题",
    "Answer To The Question": "对问题的回答",
    "Nominee": "提名人",
    "Relationship with Nominee": "与提名人的关系",
    "Nominee Contact Number": "提名人联系电话",
    "Nominee Address": "提名人地址",
    "Delete Nominee": "删除提名人",
    "Update Nominee": "更新提名人",
    "Details Updated Successfully!": "详细信息更新成功！",
    "Success": "成功",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "您可以通过InstaPay平台自行选择一名提名人，前提是他们不是未成年人。您承认，指定提名人不需要书面签名，\n且在线注册将被视为最终和具有约束力的。在InstaPay钱包持有者去世的情况下，\n钱包中的余额将被转移给注册的提名人。KEMIT KINGDOM在将金额交给提名人后，将解除其所有责任。\n提名人将提供InstaPay要求的任何文件，包括身份证明和死亡证明等。",
    "Add Nominee": "添加提名人",
    "Two Factor Authentication": "双因素认证",
    "SMS Number": "短信号码",
    "Updated Successfully!": "更新成功！",
    "Social Network Accounts": "社交网络账户",
    "Here, you can set up and manage your integration settings.": "在这里，您可以设置和管理您的集成设置。",
    "Social Network Account": "社交网络账户",
    "Activate Account": "激活账户",
    "Enter the code below in the InstaPay Chatbot.": "在InstaPay聊天机器人中输入以下代码。",
    "The code will expire in ": "该代码将在",
    "Notifications": "通知",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "自定义您接收通知的方式。这些设置适用于您正在监控的活动。",
    "Activity": "活动",
    "Email": "电子邮件",
    "Push": "推送",
    "SMS": "短信",
    "Source": "来源",
    "Payment Requests": "付款请求 ",
    "Bulk Payments": "批量支付",
    "Identity Verification": "身份验证",
    "Verify Your Identity": "验证您的身份",
    "Identity Verification Status:": "身份验证状态：",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "简要介绍您自己，并解释您使用InstaPay的原因。",
    "Enter Your Message": "输入您的信息",
    "Select Occupation": "选择职业",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "必须是带有照片的政府颁发的身份证明文件。所有身份证明文件必须使用拉丁字母书写。如果不是，\n需要经过认证的翻译。",
    "Accepted Documents": "接受的文件",
    "A national identity card": "国家身份证",
    "A valid passport": "有效护照",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "有效居留许可（仅接受欧洲国家或瑞士发行的）",
    "Biometric Facial Recognition: Comparing video with passport photograph": "生物识别面部识别：将视频与护照照片进行比对",
    "Address Verification:": "地址验证：",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "提供银行对账单或公用事业账单（不超过3个月）作为地址证明",
    "Additional Documents:": "额外文件：",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom保留要求任何额外文件（增强尽职调查 - EDD）或进一步信息的权利，以进行彻底的核查，符合其法律义务。",
    "Start Verification": "开始验证",
    "Sessions": "会话",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "这是已登录到您账户的设备列表。移除您不认识的任何设备。",
    "Your Withdrawal Options": "您的提款选项",
    "Choose your preferred method for withdrawing payments.": "选择您偏好的提款方式。",
    "No withdrawal options currently available in your country.": "您所在国家目前没有可用的提款选项。",
    "Download Your Data": "下载您的数据",
    "Request a Copy of Your Data": "请求您的数据副本",
    "Submit a request to download a copy of your data": "提交请求下载您的数据副本",
    "Which Data Should Be Included in the Download?": "下载中应包括哪些数据？",
    "Select all applicable options.": "选择所有适用选项。",
    "Personal Information": "个人信息",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "此信息用于保护您的账户，定制您的体验，并在必要时与您联系。",
    "Name and Date Of Birth": "姓名和出生日期",
    "Phone number": "电话号码",
    "Financial Information": "财务信息",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "我们使用这些信息来实现更快的结账，并在几次点击中发送或接收资金。",
    "Bank accounts": "银行账户",
    "Debit or credit cards": "借记卡或信用卡",
    "Credit Information": "信用信息",
    "Includes your credit application information": "包括您的信用申请信息",
    "Other Information (not included in file)": "其他信息（文件中不包括）",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "包括设备信息、技术使用数据、地理位置信息、市场偏好、同意历史，以及用于其他服务的数据，如信用、身份验证、\n与PayPal的通信以及第三方处理器。",
    "Choose file type:": "选择文件类型：",
    "Select file type": "选择文件类型",
    "PDF file can be easily opened on any computer": "PDF文件可以在任何电脑上轻松打开",
    "Submit Request": "提交请求",
    "Delete Your Account": "删除您的账户",
    "Ask us to delete your data and close this account": "要求我们删除您的数据并关闭此账户",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "我们使用您的数据提供服务，因此如果我们删除您的数据，我们也需要关闭您的账户。",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "如果您的账户有限制，请前往解决方案中心，选择“前往账户限制”，然后点击“解决”。",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "确保任何未完成的支付已清算。在此之前，我们无法关闭您的账户。",
    "If you have a negative balance, click the Resolve link next to your balance.": "如果您的余额为负，请点击您余额旁边的解决链接。",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "一旦您的账户清算完毕且符合条件，我们将关闭您的账户并删除您的数据。我们需要保留您的一些数据以符合法律和监管要求。",
    "privacy statement": "隐私声明",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "我们会通过电子邮件通知您状态，以及是否需要您采取其他步骤。",
    "Have more questions about data deletion?": "对数据删除有更多疑问吗？",
    "Contact us": "联系我们",
    "I understand that account deletion is permanent and can't be reversed.": "我了解账户删除是永久性的，不能撤销。",
    "Are you sure you want to close your account?": "您确定要关闭账户吗？",
    "Yes, Continue": "是的，继续",
    "Logging In": "登录",
    "Complete your profile to streamline your transaction process.": "完善您的个人资料，以简化您的交易过程。",
    "Skip": "跳过",
    "Complete Your Profile": "完善您的个人资料",
    "API Key": "API密钥",
    "Get API Key": "获取API密钥",
    "Generating New API Key": "生成新的API密钥",
    "Generate API Key": "生成API密钥",
    "Regenerate API Key": "重新生成API密钥",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "为了充分利用InstaPay无缝交易的潜力，请遵循以下简单步骤生成您的API密钥。首先，在[your registration URL]注册一个账户，\n并访问开发者仪表板。导航至‘API密钥’部分，生成一个新密钥，并确保其安全存储。",
    "Delete API Key": "删除API密钥",
    "Webhook URL": "Webhook URL",
    "KYB Verification": "KYB验证",
    "Additional Files": "附加文件",
    "Business Verification": "企业验证",
    "--Select-Your-Company-Type--": "--选择您的公司类型--",
    "Documents Required:": "所需文件：",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "官方公司注册提取文件（实体的注册/成立文件）",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "该文件作为公司的官方‘身份证’，应从当地商业注册处获得。它反映了企业的当前状态（活跃或关闭）。",
    "The document should be current, with an issuance date not exceeding 3 months.": "该文件应为最新的，签发日期不超过3个月。",
    "The document must include the following details:": "文件必须包括以下详细信息：",
    "Legal name of the company": "公司的法定名称",
    "Full registered address": "完整的注册地址",
    "Company registration number": "公司注册号码",
    "Date of incorporation": "成立日期",
    "Description of the company's activities/purpose": "公司活动/目的的描述",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "法定代表人信息：全名、出生日期和地点、住址（最低要求：居住国家）以及国籍",
    "If applicable, the document should also include:": "如果适用，文件还应包括：",
    "Brand or trading names": "品牌或交易名称",
    "Value Added Tax (VAT) number": "增值税（VAT）号码",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "最终受益人（UBOs）名单 - 在某些国家这是强制性的，否则，可以作为单独的文件提供。",
    "Proof of Legal Representative's Authority": "法定代表人授权证明",
    "This document validates an individual's authority to represent the company": "此文件验证个人代表公司的权威",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "通常，法定代表人会在公司的注册摘要中被列出。然而，如果不是这种情况，必须获得如授权书或授权委托书等文件。",
    "Power of Attorney/Delegation of Powers:": "授权书/授权委托：",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "授予授权的个人必须有合法权利这样做。这个个人应该在公司的注册摘要中被列为法定代表人。",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "所有权结构和最终受益人（UBOs）文件",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "应提供公司在相关股票交易所上市的证据。这可以是股票交易所网站上显示公司详情的页面。",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "如果公司股份少于75%是公开持有的，所有持有或控制超过25%股份的股东将需要进行最终受益人（UBO）验证。",
    "Collection of Identification Documents": "收集身份证明文件",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "身份证明文件必须由政府颁发，并附有个人的照片。所有身份证明文件必须使用拉丁字母书写。如果不是，则需要经过认证的翻译。",
    "Accepted documents:": "接受的文件：",
    "Valid National Identity Card": "有效的国家身份证",
    "Valid Passport": "有效的护照",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "有效的居留许可 - 仅在由欧洲国家发放时接受。",
    "Only legal representatives are subject to the identification document collection process.": "只有法定代表人需要提交身份证明文件的收集过程。",
    "Additional Requirement:": "额外要求：",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "在涉及授权书或授权委托的情况下，双方（授权人和被授权人）都必须提供其身份证件的副本。",
    "Additional Documents": "附加文件",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "公用事业账单（如燃气、电力、电话或手机账单）；或由政府部门出具的显示终端用户地址和姓名的文件（例如，居住证明）；或”",
    "Bank Statement; or": "银行对账单；或",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "由政府部门出具的显示终端用户地址和姓名的文件（例如，居住证明）。",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom保留要求任何附加文件或进一步信息的权利，以进行彻底的验证，符合其法律义务。",
    "Note:": "注意：",
    "Partners": "合作伙伴",
    "Add New Partner": "添加新合作伙伴",
    "Designation": "职位",
    "Partner Name": "合作伙伴姓名",
    "Partner Email": "合作伙伴电子邮件",
    "Partner Designation": "合作伙伴职位",
    "Add Now": "立即添加",
    "Adding": "正在添加",
    "Delete Partner": "删除合作伙伴",
    "Delete": "删除",
    "Deleting": "正在删除",
    "Are you sure, you want to remove sxz as partner?": "您确定要移除sxz作为合作伙伴吗？",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "实体的注册摘要（实体注册/创建文件）",
    "This document should be either:": "此文件应为以下之一：",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "实体创建的证明，提供实体的详细信息、创建日期和目的。",
    "A local registry extract:": "当地注册摘要：",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "根据该国家的法规要求，作为实体当前的“身份证”，指示其状态（活跃或关闭）。",
    "If a registry extract exists, it must not be older than 3 months.": "如果存在注册摘要，其有效期不得超过3个月。",
    "Entity name": "实体名称",
    "Registration number (if applicable, based on the country's requirements)": "注册号（如果适用，基于该国的要求）",
    "Date of creation": "创建日期",
    "Description of the entity's activities or purpose": "实体活动或目的的描述",
    "Trading names": "交易名称",
    "Articles of Association": "公司章程",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "公司章程作为实体的宪法文件，概述其运营规则。",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "此文件没有时间限制，意味着需要最新版本。通常，这类实体至少需要每年举行一次会议，可能会导致公司章程的更新版本。",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "可能发生的变更示例包括更新实体的名称、地址、目的、管理委员会、角色、职责等。",
    "The document should contain information about:": "文件应包含有关以下内容的信息：",
    "The entity itself, including its legal name, registered address, and purpose": "实体本身，包括其法定名称、注册地址和目的",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "其内部运营规则，例如管理委员会成员的任命过程、他们的角色和权力等细节。此列表不详尽。",
    "How the entity is funded": "实体的资金来源",
    "Appointment/Removal/Changes on Board of Management Members:": "管理委员会成员的任命/移除/更改：",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "这些更新通常基于实体的需求或根据公司章程的规定进行。变更通过管理委员会会议记录进行记录，\n并注册认证以反映更新的成员名单，如主席、副主席、财务主管和秘书长。",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "除了公司章程外，还需要最新的管理委员会会议记录，包括当前的成员名单。",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "注意：如果最新的公司章程中未包含当前管理委员会成员名单且没有可用的董事会会议记录，则必须提供此名单。\n该名单应由最新任命的法定代表人签署，并注明日期，其任命可以通过相关文件验证。",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "此文件证明个人代表实体的权威。此文件可能是：",
    "The latest Articles of Association": "最新的公司章程",
    "The current list of board management members (which may be included in the board management meeting minutes)": "当前的管理委员会成员名单（可能包含在管理委员会会议记录中）",
    "The document should specify:": "文件应具体说明：",
    "Full name of the individual(s)": "个人的全名",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "个人的职能。这些信息将与公司章程进行交叉验证，以确保个人的职能允许他们代表实体行事。当申请人不是主席时，\n这一点尤其重要，因为公司章程中规定其他职能的代表权限是有限的。",
    "Additional information about the legal representative that will be collected includes:": "将收集的关于法定代表人的额外信息包括：",
    "Date and place of birth": "出生日期和地点",
    "Residential address (at least the country of residence should be stated)": "住址（至少应说明居住国家）",
    "Nationality": "国籍",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "所有权结构与最终受益人（UBOs）",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "通常，鉴于这类实体的结构和目的，它们没有受益所有者。然而，出于筛查目的、身份证收集和验证等原因，\n董事会成员将被识别并视为受益所有者。",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "少数协会可能有受益所有者，特别是那些产生利润的协会。在这些情况下，公司章程中会提及这一细节。\n这些个体将被轻松识别并与管理委员会成员一同视为受益所有者。",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "附加要求：在涉及授权书或授权委托的情况下，双方（授权人和被授权人）都必须提供其身份证件的副本。",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "个体经营者的注册摘要（实体注册/创建文件）",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "此文件作为个体经营者的当地注册，在要求此类注册的国家是必需的。它验证该个人作为个体经营者从事专业活动。\n这份文件的名称因国家而异。",
    "Where applicable, the document should not be older than 3 months.": "如适用，该文件不得超过3个月。",
    "The document must contain:": "文件必须包含：",
    "Individual's name": "个人姓名",
    "Residential address": "住址",
    "Registration number": "注册号",
    "The sole trader is the only individual subject to the identification document collection process.": "个体经营者是唯一需要提交身份证明文件的个体。",
    "No Transactions": "无交易",
    "No Pending Items": "无待处理项目",
    "No Beneficiaries": "无受益人",
    "Send Money To": "发送资金至",
    "Confirmation": "确认",
    "You are setting": "您正在设置",
    "as default.": "为默认。",
    "Previously": "之前",
    "was default QR.": "是默认二维码。",
    "Category": "类别",
    "-Select-From-The-List-": "-从列表中选择-",
    "Add a note": "添加注释",
    "Categories Added Successfully!": "类别添加成功！",
    "Subscription starting date": "订阅开始日期",
    "Until": "直到",
    "Next Transaction": "下一笔交易",
    "Transaction Method": "交易方式",
    "Accept the quotation": "接受报价",
    "Choose Currency": "选择货币",
    "Accept": "接受",
    "Bargain the quotation": "议价报价",
    "Enter Counter Amount:": "输入还价金额：",
    "Bargain": "议价",
    "Decline the quotation": "取消报价",
    "Are you sure, you want to decline this quotation?": "您确定要拒绝这个报价吗？",
    "Decline": "拒绝",
    "Personal Account": "个人账户",
    "Jan": "一月",
    "Feb": "二月",
    "Mar": "三月",
    "Apr": "四月",
    "May": "五月",
    "Jun": "六月",
    "Jul": "七月",
    "Aug": "八月",
    "Sep": "九月",
    "Oct": "十月",
    "Nov": "十一月",
    "Dec": "十二月",
    "Today": "今天",
    "Spent In": "花费在",
    "Revised Amount": "修订金额",
    "You spent more comparing to last month": "与上个月相比，你花费了更多",
    "Last": "上个",
    "days": "天",
    "Year": "年",
    "Instant Mobile Top-up Across 150+ Countries": "150多个国家的即时手机充值 ",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "不论您身在何处，都能为您的亲人或自己的手机充值！InstaPay的移动空中充值服务让您能够在150多个国家立即为手机充值。完美的礼物或确保您始终在线，我们的服务为简便、快速和安全而设计。 ",
    "How It Works - Step-by-Step Guide:": "如何工作——分步指南：",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. 选择'手机充值'：在WhatsApp、Twitter、Telegram或Instagram等平台上的InstaPay应用程序中直接访问此功能。",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. 选择货币和金额：从多种货币中选择（USD、GBP、EUR等），并指定您想要充值的金额。",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. 输入手机号码：输入您希望充值的号码——您自己的或亲人的。我们的系统确保安全快速的过程。",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. 确认并支付：再次检查充值细节，确认并使用您喜欢的方式付款。交易即刻安全地处理。",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. 接收即时确认：一旦充值成功，您和收件人（如果适用）将收到确认通知。 ",
    "Benefits:": "好处：",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "全球覆盖：轻松向150多个国家发送空中时间。",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "即时影响：立即看到好处——完美的最后一刻礼物或紧急通信。",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "完全安全：享受我们安全支付系统带来的心安。",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "多样化支付选项：从信用卡、PayPal或您的InstaPay钱包中选择支付方式。 ",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "准备好传播快乐或保持联系了吗？现在就充值，让某人的一天变得特别——或者是您自己的一天！",
    "Pick a language": "选择语言",
    "French": "法语",
    "Spanish": "西班牙语",
    "Portuguese": "葡萄牙语",
    "German": "德语",
    "Ukrainian": "乌克兰语",
    "Italian": "意大利语",
    "Russian": "俄语",
    "Arabic": "阿拉伯语",
    "Polish": "波兰语",
    "Dutch (Nederlands)": "荷兰语",
    "Yoruba": "约鲁巴语",
    "Indonesian": "印尼语",
    "Turkish": "土耳其语",
    "Swahili": "斯瓦希里语",
    "Hausa": "豪萨语",
    "Hindi": "印地语",
    "Urdu": "乌尔都语",
    "Chinese": "中文",
    "Select Your Preferred Language": "选择您偏好的语言",
    "Please select the language you would \nlike to use for the eKYC process.": "请选择您希望用于eKYC过程的语言。",
    "Select Your Nationality": "选择您的国籍",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "选择您的国籍以查看您所在国家支持的身份证件。",
    "Grant permission to use the camera.": "授予使用相机的权限。",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "在提示授予相机访问权限以捕获文件时，点击或单击“允许”。",
    "Upload Your Documents from \nGallery/Photos": "从图库/照片上传您的文件",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "如果您遇到相机问题或系统没有相机，您可以从本地存储手动上传文件照片。",
    "Upload Your ID.": "上传您的身份证。",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "确保您的身份证清晰且不模糊，以确保快速验证。",
    "Valid Proof of Address": "有效的地址证明",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. 确保您的地址证明是当前的，并且对账单日期是从您的 eKYC 验证开始日期起的三个月内。\n\r\n2. 确保您的地址证明清晰且不模糊，以确保快速验证。\r\n",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "重要通知\n\n在开始您的 eKYC 验证之前，请仔细阅读本指南中的所有步骤。\n\n避免常见错误：\n* 确保您的所有文件清晰、可读且最新。\n* 确认您提供的信息与官方文件中的信息一致。\n* 仔细按照说明进行操作，以避免延误或拒绝。\n\n注意： \n如果您的 eKYC 验证失败并且需要重新提交，重新提交将收取额外费用。\n\n通过遵循这些指南，您可以确保 eKYC 验证过程的顺利和成功。\n\n如有任何疑问或需要帮助，请通过 support@insta-pay.ch 联系我们的支持团队。\n\n",
    "Personal Transactions": "个人交易",
    "Business Transactions": "商业交易",
    "Shopping & Purchases": " 购物和购买",
    "Bills & Utilities": "账单和公用事业",
    "Entertainment & Leisure": " 娱乐和休闲",
    "Investments & Savings": "投资和储蓄",
    "Health & Wellness": "健康与保健",
    "Transportation": "交通",
    "Education": "教育",
    "Miscellaneous": "杂项",
    "Family Support": "家庭支持",
    "WALLET": "WALLET",
    "Add a currency": "添加货币",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "当您添加一种货币时，您在该货币中收到的任何付款将被记入您的InstaPay余额。除非另有说明，否则您的主要货币将用于发送或请求付款。",

    "balance amount ": "余额金额",
    "referral ": "推荐",
    "crypto wallet balance": "加密钱包余额",
    "money in": "收入",
    "money out": "支出",
    "analytics": "分析",
    "see more": "查看更多",
    "manage my Wallets and see the transaction details": "管理我的钱包并查看我的交易明细",
    "set as default Wallet": "设为默认钱包",
    "add currency": "添加货币",
    "convert funds ": "转换资金",
    "withdraw balance": "提取余额",
    "chart names": "图表名称",
    "withdraw balance from PKR wallet": "从PKR钱包提取余额",
    "select a payout channel": "选择一个支付渠道",
    "you have entered an amount below the minimum payment range": "您输入的金额低于最低付款范围",
    "change": "更改",
    "edit": "编辑",
    "moving to next step in ...": "下一步将在...",
    "you are withdrawing ...": "您正在提取 ...",
    "where is my payment": "我的付款在哪里",
    "download": "下载",
    "IBAN / SWIFT Code": "IBAN 或 SWIFT/BIC 代码",
    "swift code": "SWIFT 代码",
    "account number": "账号",
    "branch street": "分行街道",
    "city": "城市",
    "province": "省",
    "postal code": "邮政编码",
    "all payout channels": "所有支付渠道",
    "bussiness beneficiary": "商业受益人",
    "no quotation": "没有报价",
    "no pending items": "没有待处理的项目",
    "QR status": "QR状态",
    "Portfolio": "投资组合",
    "edit whole portfolio page": "编辑整个投资组合页面",
    "personal account": "个人账户",
    "Chart names": "图表名称",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "更多",
    "not connected": "未连接",
    "code": "代码",
    "ratings": "评分",
    "current password": "当前密码",
    "include at least one uppercase and lowercase": "至少包含一个大写和小写字母",
    "my social network accounts": "我的社交网络帐户",
    "SMS ": "短信",
    "view identity verfication guide": "查看身份验证指南",
    "boimetric face recognition": "生物面部识别",
    "please select a payer (withdrawl options)": "请选择付款人（提款选项）",
    "IBAN number ": "IBAN号",
    "account holder name": "账户持有人姓名",
    "download data": "下载数据",
    "bank transfer": "银行转账",
    "mobile wallet": "移动钱包",
    "all bank names": "所有银行名称",
    "pay via card": "通过卡支付",
    "you have entered an amount that is below the payer range": "您输入的金额低于付款范围",
    "trouble sending money": "汇款问题",
    "please follow these example": "请按照这些示例操作",
    "wallet ID ": "钱包ID",
    "username": "用户名",
    "email": "电子邮件",
    "phone number": "电话号码",
    "no user found": "未找到用户",
    "search": "搜索",
    "scan/upload QR code of the recipient": "扫描/上传收款人的二维码",
    "invite them": "邀请他们",
    "trouble quoting money": "报价问题",
    "select a country": "选择国家",
    "airtime details": "充值详情",
    "enter the phone number": "输入电话号码",
    "service type": "服务类型",
    "select a service": "选择服务",
    "service list": "服务列表",
    "amount entered is less than the ....": "输入的金额少于....",
    "total": "总计",
    "total credit the receiver gets": "收件人获得的总信用额度",
    "take ": "取",
    "OTP has been verified , You'll be redirected......": "OTP已验证，您将被重定向......",
    "Link Your Instagram Account to InstaPay": "链接您的 Instagram 账户到 InstaPay",
    "Step 01 – From Instagram App": "步骤 01 – 从 Instagram 应用程序",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "打开 Instagram 应用程序，搜索“InstaPay”个人资料页面，选择“消息”以打开聊天框，输入/说“你好”，然后点击发送以启动与 InstaPay 聊天机器人的对话。",
    "Step 02 – From InstaPay Instagram Profile Page ": "步骤 02 – 从 InstaPay Instagram 个人资料页面",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "InstaPay 聊天机器人将显示两个操作按钮：“注册”和“连接”。选择“连接”，聊天机器人会提示您输入您的 InstaPay 用户名。",
    "Step 03 – From InstaPay Instagram Profile Page": "步骤 03 – 从 InstaPay Instagram 个人资料页面",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "InstaPay 聊天机器人随后会要求提供 InstaPay 验证码。输入您从 InstaPay 设置中获取的“我的社交网络账户”下的代码，然后点击发送。",
    "Step 04 – From InstaPay Instagram Profile Page": "步骤 04 – 从 InstaPay Instagram 个人资料页面",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "您将收到一条确认信息，确认您的 Instagram 账户现已与 InstaPay 同步。点击“主菜单”开始使用 InstaPay 服务。",
    "Additional Tips": "附加提示",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. 保护您的账户：通过启用双因素身份验证（2FA）确保您的 Instagram 账户安全，以保护您的 InstaPay 相关服务。",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. 探索更多：一旦链接后，探索 InstaPay 提供的广泛服务，从无缝交易到即时提现。",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. 保持更新：请关注我们的更新，因为我们将很快支持链接其他社交媒体账户，使您的体验更加一体化。",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. 帮助与支持：如果在链接过程中遇到任何问题，请联系我们的支持团队寻求帮助。",
    "Future Enhancements": "未来的改进",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "在不久的将来，InstaPay 将允许您链接更多的社交媒体账户，为您提供更多的灵活性和便利性。敬请期待令人兴奋的更新！",
    "Personal Transactions": "个人交易",
    "Business Transactions": "商业交易",
    "Shopping & Purchases": " 购物和购买",
    "Bills & Utilities": "账单和公用事业",
    "Entertainment & Leisure": " 娱乐和休闲",
    "Investments & Savings": "投资和储蓄",
    "Health & Wellness": "健康与保健",
    "Transportation": "交通",
    "Education": "教育",
    "Miscellaneous": "杂项",
    "Family Support": "家庭支持",
    "Login With Phone Number": null,
    "Login": "登录",
    "Start Your InstaPay Journey Today!": "今天就开始您的 InstaPay 之旅吧！",
    "Enter your phone number": "输入您的电话号码",
    "Login with email": "使用电子邮件登录",
    "Don't have an account?": "没有账户？",
    "Sign Up": "注册",
    "Continue": "继续",
    "We recommend you download and install the Google Authenticator app": "我们建议您下载并安装 Google Authenticator 应用程序",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "双因素认证 (2FA) 是在线保护自己的最佳方式。",
    "Strong security with Google Authenticator": "使用 Google Authenticator 提供强大的安全性",
    "Enter your password": "输入您的密码",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "我们建议您下载并安装 Google Authenticator 应用程序，以确保您即时收到验证代码，避免任何短信延迟或问题。",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "双因素认证代码",
    "Problem with the token?": "令牌有问题？",
    "Verify using SMS": "使用短信进行验证",
    "Verify": "验证",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "短信验证",
    "Enter the SMS code sent to +41********4053": "输入发送到 +41********4053 的短信代码",
    "Verify using Authenticator App": "使用身份验证器应用进行验证",
    "Resend code in 120 seconds": "120 秒后重新发送代码",
    "Verify ": "验证",
    "SMS - Web Mobile": null,
    "My own account": "我的账户",
    "Friends & Family": "朋友和家人",
    "Aunt": "姑姑",
    "Brother-in-law": "姐夫",
    "Cousin": "堂兄弟姐妹",
    "Daughter": "女儿",
    "Father": "父亲",
    "Father-in-law": "岳父",
    "Friend": "朋友",
    "Grandfather": "祖父",
    "Grandmother": "祖母",
    "Husband": "丈夫",
    "Mother": "母亲",
    "Mother-in-law": "岳母",
    "Nephew": "侄子",
    "Niece": "侄女",
    "Self (i.e. the sender, himself)": "自己（即发送者）",
    "Sister": "姐妹",
    "Sister-in-law": "嫂子",
    "Son": "儿子",
    "Uncle": "叔叔",
    "Wife": "妻子",
    "Others not listed": "其他未列出的",
    "Merchant": "商家",
    "No relationship": "无关系",
    "Add Funds": "添加资金",
    "Enter the Amount": "输入金额:",
    "Fees": "费用:",
    "You Will Get": "您将收到:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ 交易金额应在 [Currency ISO code & Amount] - [Currency ISO code & Amount] 之间",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ 金额无效：您当前的身份验证级别限制了您可以添加的最大金额。请升级您的身份验证以享受更高的限额。",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ 货币价格经常根据市场情况变化。请参考确认订单页面上的价格作为您的最终报价。",
    "Next": "下一步",
    "Upgrade Identity Verification": "升级身份验证",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "向任何人、任何地方请求付款，包括社交网络平台。使用InstaPay，您的付款请求在被接受后会立即生效，资金会发送到您的接收账户。简化您的交易，并轻松安全地管理付款。",
    "You’ve been logged out.": "您已被登出。",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "为了您的安全，由于15分钟未活动，您已被登出。如果您希望继续使用InstaPay，请重新登录。",
    "LOGIN AGAIN": "重新登录",
    "Brother": "兄弟",
    "Current Password": "当前密码",
    "The new password cannot be the same as the old one": "新密码不能与旧密码相同。",
    "Minimum password length: [X] characters ": "密码最少长度：[X] 字符",
    "Include at least one uppercase and one lowercase letter.": "包含至少一个大写字母和一个小写字母。",
    "Nominee Information": "提名人信息",
    "Verification Code Preferences": "验证代码偏好",
    "How Others Can Find You": "其他人如何找到您",
    "My Social Network Accounts": "我的社交网络账户",
    "Login Activity": "登录活动",
    "Receiving Accounts": "接收账户",
    "Change Your Password To Continue": "更改密码以继续",
    "Continue with": "继续",
    "Back to Login": "返回登录",
    "Send OTP": "发送OTP",
    "A Verification code has been sent to your email and number.": "验证码已发送到您的电子邮件和号码。",
    "Resend available in": "重新发送可用",
    "Create a strong Password": "创建一个强密码",
    "Password must:": "密码必须：",
    "Be between 9 and 64 characters": "介于9到64个字符之间",
    "Include at least two of the following:": "至少包括以下两项：",
    "Lowercase Character": "小写字符",
    "Special Character": "特殊字符",
    "Password Updated Successfully!": "密码更新成功！",
    "Click below to login": "点击下面登录",

    "Send Money": "汇款",
    "Enter the amount": null,
    "Recent Transfer": "最近的转账",
    "See all": "查看全部",
    "Send again": "再次汇款",
    "You Send": "您汇款",
    "They Receive": "他们收到",
    "Receive Method": "接收方式",
    "Select method": "选择方式",
    "Next": "下一步",
    "Trouble sending money?": "汇款有问题？",
    "Where do you want to send money ?": "您要汇款到哪里？",
    "Search ": "搜索",
    "Confirm": "确认",
    "Bank Deposit": "银行存款",
    "Cash Pick-up": "现金提取",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "银行卡",
    "Crypto Wallet": "加密Wallet",
    "What is the receiver’s Mobile Wallet?": "收款人的手机Wallet是什么？",
    "Transfer fees": "转账费用",
    "Total to pay": "总支付金额",
    "Choose the beneficiary!": "选择受益人！",
    "Country": "国家",
    "Receive method": "接收方式",
    "You send": "您汇款",
    "change": "更改",
    "edit": "新增",
    "add new": "受益人",
    "Beneficiaries": "管理受益人",
    "Manage Beneficiaries": "搜索",
    "Search": "下一步",
    "CASH PICK-UP": "现金取款",
    "Where will your receiver collect the money": "您的收款人将在哪里领取现金？",
    "Cash is available to collect within minutes from any branch of [name] ": "现金可在几分钟内从任何[name]分行领取",
    "BANK ACCOUNT": "银行账户",
    "Transfer to recipient’s bank account": "转账到收款人的银行账户",
    "BANK CARD": "银行卡",
    "Fast transfer directly to recipient’s bank card": "快速转账直接到收款人的银行卡",
    "CRYPTO WALLET": "加密Wallet",
    "Fast transfer directly to a crypto wallet": "快速转账直接到加密Wallet",
    "Review transfer": "查看转账",
    "Receiver's Details": "收款人详情",
    "Edit": "编辑",
    "Name": "姓名",
    "Reason for sending": "汇款原因",
    "Sending": "发送",
    "Receiver gets": "收款人收到",
    "Exchange rate": "汇率",
    "Estimated time": "预计时间",
    "Select Payment Type": "选择支付类型",
    "Credit cards": "信用卡",
    "Domestic cards": "国内卡",
    "Mobile Wallets": "手机Wallet",
    "InstaPay Wallet": "InstaPay Wallet",
    "Crypto (USDT, ETH, BTC)": "加密货币（USDT，ETH，BTC）",
    "PayPal": "PayPal",
    "CREDIT CARD": "信用卡",
    "Credit Card": "信用卡",
    "Select card": "选择卡",
    "Card Number": "卡号",
    "Enter the 16-digit card number on the card": "输入卡上的16位卡号",
    "Expiry Date": "到期日期",
    "Enter the expiration date of the card": "输入卡的到期日期",
    "CVV Number": "CVV号",
    "Enter the 3 or 4 digit number on the card": "输入卡上的3或4位数CVV号",
    "INSTAPAY WALLET": "INSTAPAY Wallet",
    "Select Wallet": "选择Wallet",
    "My Wallet": "我的Wallet",
    "Conversion": "转换",
    "Convert": "转换",
    "Your payment is confirmed": "您的支付已确认",
    "Thanks for using InstaPay": "感谢使用InstaPay",
    "Status": "状态",
    "Share": "分享",
    "Transfer created": "转账已创建",
    "Payment received": "支付已收到",
    "Payment processed": "支付已处理",
    "Transfer successful": "转账成功",
    "It may take few hours for the funds to appear in [user name] account": "资金可能需要几小时才能出现在[user name]账户中",
    "Transfer failed": "转账失败",
    "Transaction Details": "交易详情",
    "Need help?": "需要帮助？",
    "Service Details": "服务详情",
    "Funds Deposit Speed": "资金可用性",
    "Cut-off Time": "截止时间",
    "Bank holiday list": "银行假期列表",
    "Threshold limits": "交易限额",
    "null": null,
    "Instant, Real Time": "即时，实时",
    "Same day, T+1": "当天，T+1",
    "Non-instant, T+1": "非即时，T+1",
    "Instant Payment, Real Time": "即时付款，实时",
    "Transaction processed before 3:00 PM Local time": "交易在当地时间下午3:00之前处理",
    "Delivered the same day": "当天送达",
    "Transactions processed post 3:00 PM": "交易在下午3:00之后处理",
    "T+1 business days": "T+1个工作日",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "在工作日下午3:00之前进行的付款将在当天午夜之前记入接收银行账户。",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "在工作日下午3:00之后进行的付款将在次日午夜之前记入接收银行账户。",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "在周六、周日或公共假期进行的付款将在下一个工作日午夜之前记入账户。",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 当地时间",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "每笔交易的最低限额：",
    "Maximum per transaction limit:": "每笔交易的最高限额：",
    "Daily threshold limit:": "每日限额：",
    "Weekly threshold limit:": "每周限额：",
    "Monthly threshold limit:": "每月限额：",
    "Yearly threshold limit:": "每年限额：",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "基础账户（未验证）",
    "Identity Verified": "身份已验证",

    "Good Evening!": "好的晚上！",
    "Total Balance": "账户总余额",
    "Quick Transfer": "快速资金转账",
    "No transactions": "未找到交易",
    "Blocked balance": "冻结余额",
    "Available balance": "可用余额",
    "Insufficient Balance in PKR": "PKR余额不足",
    "Chat with us": "与我们聊天",
    "Add Currency": "添加货币",
    "Education": "教育",
    "Divers": "杂项",
    "Family support": "家庭支持",
    "Others": "其他费用",
    "Transport": "交通",
    "schedule payment": "计划付款",
    "subscription payment": "定期付款",
    "No payment": "未找到付款",
    "No Beneficiaries": "没有可用的受益人",
    "Code Postal": "邮政编码",
    "Additional Information": "附加信息",
    "Gender": "性别",
    "Date Of Birth": "出生日期",
    "Nationality": "国籍",
    "Occupation": "职业（包括所有选项）",
    "ID Type": "证件类型（包括所有选项）",
    "ID Number": "证件号码",
    "Select Beneficiary Payment Channels": "选择受益人支付渠道",
    "wallet crypto": "加密钱包",
    "Add another wallet": "添加另一个钱包",
    "Enterprise": "商业",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "“商业受益人即将到来！您将能够在全球范围内进行商业交易，扩大您的跨境影响力。”",
    "Joined At": "加入日期",
    "Profile Link": "个人资料链接",
    "comission": "佣金",
    "my withdrawls": "我的提款",
    "My Referrals": "我的推荐",
    "search quotation": "搜索报价",
    "no quotation": "未找到报价",
    "actions": "操作",
    "search request": "搜索请求",
    "no pending items ": "未找到待处理项目",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "分享您的二维码或付款链接，以便即时收到InstaPay钱包的付款",
    "Description": "描述",
    "save": "保存",
    "Share your Insta-Pay unique payment link": "分享您的独特InstaPay付款链接",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "提升您的公众形象。您的投资组合作为一个动态展示平台，可以直接通过您的付款地址访问",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "通过您的专业或创意之旅与您的观众联系。让您的作品展示自己，更深地吸引潜在客户或支持者。",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "展示您的社交媒体影响力。访客可以看到您的社交媒体影响，增加您的投资组合的可信度。",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "允许访客直接从您的公共页面请求或发起付款。您的投资组合不仅是展示工具，也是便捷的金融交易方式。",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "使用“关于我”部分分享您的故事、使命或愿景，与您的观众建立个人联系。",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "“我的投资组合”不仅仅是一个画廊，它是一个建立品牌、与观众互动并简化InstaPay金融交互的工具。",
    "No Portfolio Items": "未找到投资组合项目",
    "Add New Portfolio Item": "添加新的投资组合项目",
    "Title": "标题",
    "drag/drop or upload a file(photo/video/pdf)": "拖放或上传文件（照片/视频/pdf）",
    "discard": "放弃",
    "save and post ": "保存并发布",
    "My Payment Address": "我的付款地址",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "非常适合网红、艺术家、企业家或任何希望让他们的社区轻松表达支持的人。",
    "Personal Account": "个人账户状态",
    "spent in total": "总消费",
    "not connected": "未连接",
    "more": "更多",
    "less": "更少",
    "profile reports": "个人资料报告",
    "All notifications are in English langauge": "所有通知都以英语提供",
    "timezone": "时区",
    "nominee information": "被提名人信息",
    "full name": "全名",
    "Relation with Nominee": "与被提名人的关系",
    "source": "来源",
    "Google Authenticator": "谷歌身份验证器",
    "notifications": "通知",
    "login": "登录",
    "View Identity Verification Guide": "查看身份验证指南",
    "verified": "已验证",
    "select ": "选择",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "必须是政府签发的带有照片的身份证明文件。所有身份证明必须为拉丁字母，否则需要经过认证的翻译。",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom保留要求提供其他文件（增强的尽职调查 - EDD）或执行完整验证所需的任何信息的权利，以履行其法律义务。",
    "sessions": "会话",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "解锁即时提款：现在设置您的接收账户！",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "通过添加您的银行账户、移动钱包、加密钱包、支付卡或现金取款点的详细信息，轻松管理您想要接收付款的位置。请按照以下步骤确保在需要时即时访问您的资金。",
    "Select the receiving channel below and setup the details.": "选择下面的接收渠道并设置详细信息。",
    "Please Select A Payer": "请选择付款人",
    "Number": "号码",
    "Account Holder Name": "账户持有人姓名",
    "Province": "省份",
    "postal": "邮政地址",
    "delete channel": "删除渠道",
    "set as default": "设置为默认",
    "edit": "编辑",
    "all channels are in english language in recieving account page": "接收账户页面上的所有渠道均为英文",
    "select file type": "选择文件类型",
    "download data": "下载数据",
    "INTERNATIONAL TRANSFER": "国际转账",
    "enter the amount": "输入金额",
    "they recieve ": "收款人收到",
    "trouble sending money": "发送资金有问题吗？",
    "select destination country": "选择目的地国家",
    "bank transfer": "银行转账",
    "recieving method": "接收方式",
    "payer": "付款人",
    "The fee and the received amount may vary depending on the chosen Payment Method": "费用和接收到的金额可能因所选的支付方式而有所不同。",
    "search ": "搜索",
    "show more": "显示更多",
    "select remark reasons": "选择备注原因",
    "credit and debit card": "信用卡和借记卡",
    "Transfer Fees": "转账费",
    "Estimated Time": "预计时间：",
    "within minutes": "几分钟内",
    "total ": "总额",
    "OTP verifications code": "OTP验证码",
    "verify using sms/email": "通过短信/电子邮件验证",
    "sms/email verification code": "短信/电子邮件验证码",
    "verify using authenticator": "使用身份验证器验证",
    "WALLET TO WALLET TRANSFER": "钱包到钱包转账",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "输入收款人的钱包ID、用户名、电子邮件或电话号码。",
    "search": "搜索",
    "lookup": "查询",
    "Recipient Not on InstaPay?": "收款人不在InstaPay上？",
    "invite them": "邀请他们",
    "Please follow these examples": "请参考以下示例：",
    "Wallet ID: GY68J782": "钱包ID: GY68J782",
    "InstaPay/Instagram Username": "InstaPay/Instagram用户名: instauser",
    "Email: user@email.com": "电子邮件：user@email.com",
    "With Country code": "电话号码（含国家代码）",
    "invite friend": "邀请朋友",
    "invite via phone number": "通过电话号码邀请",
    "invite via email address": "通过电子邮件地址邀请",
    "next": "下一步",
    "back": "返回",
    "Personalized Message": "个性化信息",
    "available currency": "可用货币",
    "attach files": "附加文件",
    "total amount": "总金额",
    "instant payment": "即时支付",
    "starting from": "开始于",
    "recurring cycle": "循环周期",
    "until I stop": "直到停止",
    "start from": "从",
    "cycle": "循环",
    "total": "总额",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "收款人可能会由于汇率收到略有调整的金额。",
    "schedule to": "计划至",
    "schedule at": "计划于",
    "Scheduled Date": "计划日期",
    "Scheduled time": "计划时间",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "收款人可能会由于汇率收到略有调整的金额。",
    "QR CODE PAYMENT ": "二维码支付",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "或者，使用InstaPay字母数字二维码。",
    "PYAYMENT REQUEST": "支付请求",
    "choose the beneficiary": "选择受益人",
    "trouble requesting money": "请求资金有问题吗？",
    "Standard Request": "标准请求",
    "Subscription Request": "定期请求",
    "Schedule Request": "计划请求",
    "Security Request": "保证金请求",
    "Instalment Request": "分期付款计划",
    "Split Request": "分拆付款",
    "You recieved": "你已收到",
    "is this your location?": "这是您的位置吗？",
    "yes": "是",
    "Authenticator Code": "认证码",
    "Enter a code shown in the app to make sure everything works fine.": "在应用中输入显示的代码以确保一切正常。",
    "SEND A QUOTE": "发送报价",
    "Trouble Quoting Money?": "发送报价有问题吗？",
    "Please follow these examples:": "请参考以下示例：",
    "Full Name: Muhammad Imtiaz": "全名：Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "InstaPay/Instagram用户名：instauser",
    "Phone Number: 44795396600 (With Country Code)": "电话号码：44795396600（含国家代码）",
    "SEND TOP-UP AND E-SIM": "发送充值和电子SIM卡",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "保持全球连接！InstaPay 的充值服务允许您立即为手机充值、购买 eSIM、品牌代金券、礼品卡，并支付 150 多个国家的水电费。是送礼或保持准备状态的理想选择，我们的服务优先考虑便捷、速度和安全性。",
    "MOBILE AIRTIME AND E-SIM": "移动话费和电子SIM卡",
    "Select the country where you want to top-up a mobile phone!": "选择移动充值的国家！",
    "Other Operator": "其他运营商",
    "airtime": "话费",
    "bundle": "套餐",
    "data": "数据",
    "Enter custom amount": "输入自定义金额",
    "Credit & Debit Card": "信用卡与借记卡",
    "Bank Transfer": "银行转账",
    "Mobile Wallet": "移动钱包",
    "InstaPay Wallet": "InstaPay 钱包",
    "PayPal": "PayPal",
    "add card payment": "添加卡支付",
    "save details to use next time.": "保存详细信息以供将来使用",
    "Recipient Mobile Number": "收件人手机号码",
    "Amount": "金额",
    "Service": "服务",
    "Destination": "目的地",
    "Provider": "供应商",
    "Airtime": "话费",
    "Pakistan": "巴基斯坦",
    "Telenor Pakistan": "巴基斯坦天翼电信",
    "Within minutes": "几分钟内",
    "top-up summary": "充值摘要",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PayPal 不支持 PKR，USD 将作为默认货币使用。",
    "Amount in USD": "金额（以 USD 计）",
    "Send Top-up": "发送充值",
    "available sunservices": "可用的 Sunservices",
    "login with email": "通过电子邮件登录",
    "Already Have an Account?": "已有账户？登录",
    "profile picture": "个人资料图片",
    "add photo/logo": "添加照片/标志",
    "first name": "名字",
    "enter your first name": "输入您的名字",
    "last name": "姓氏",
    "enter your last name": "输入您的姓氏",
    "email(optional)": "电子邮件（可选）",
    "enter your email address": "输入您的电子邮件地址",
    "password must": "密码必须",
    "It will be autofill, if you're signing up with invitation link": "邀请注册的自动填写",
    "change number": "更改号码",
    "signup error": "注册错误",
    "Something went wrong while sending sms!": "发送短信时出现问题！请稍后再试。",
    "Errors": "发生错误。请参考以下列表了解详情。",
    "Generic error": "通用错误",
    "Trust Payments API requires the 'requests' library": "Trust Payments API 需要 'requests' 库才能正常运行。",
    "Send error": "发送过程中出错。请重试。",
    "Receive error": "接收过程中出错。请重试。",
    "Invalid credentials provided": "凭证无效。请验证并重试。",
    "An issue occurred whilst trying to connect to Trust Payments servers": "连接到 Trust Payments 服务器时发生问题。请检查您的连接并重试。",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "连接到 Trust Payments 服务器时发生意外错误。如果问题仍然存在，请联系 support@trustpayments.com。",
    "Unknown error. If this persists please contact Trust Payments": "未知错误。如果问题继续，请联系 Trust Payments 支持。",
    "Incorrect usage of the Trust Payments API": "Trust Payments API 的使用不正确。请验证请求并重试。",
    "Invalid card details": "卡详情无效。请检查并输入正确的详细信息。",
    "Invalid date": "提供的日期无效。",
    "Invalid date/time": "日期/时间格式无效。请提供正确的格式。",
    "Invalid details": "详细信息无效。请验证并输入正确的信息。",
    "Card number does not match card type": "卡号与指定的卡类型不匹配。",
    "Malformed XML": "XML 格式错误。请验证并更正 XML。",
    "XML does not match schema": "XML 与预期的架构不匹配。",
    "Invalid file format": "文件格式无效。请使用支持的格式。",
    "Empty file contents": "文件内容为空。请提供有效内容。",
    "Invalid file contents": "文件内容无效。请验证文件并重试。",
    "Malformed JSON": "JSON 格式错误。请更正 JSON 格式。",
    "StApi Error": "StApi 错误",
    "Invalid fields specified in request": "请求中指定的字段无效。请验证并更正它们。",
    "Missing parent": "缺少父信息。",
    "Refund requires settled parent or parent that's due to settle today": "退款需要已结算的父交易或今天待结算的交易。",
    "Refund requires authorisation parent": "退款需要授权的父交易。",
    "Refund amount too great": "退款金额超过允许的限制。",
    "No acquirer specified": "未提供收购方信息。",
    "Repeat amount too great": "重复金额超过允许的限制。",
    "Split amount too great": "分拆金额超过允许的限制。",
    "Cannot refund a declined transaction": "无法退款被拒绝的交易。",
    "Refund requires a settled parent": "退款需要已结算的父交易。",
    "Reversal requires a cancelled auth parent": "撤销需要取消的授权交易。",
    "Cannot override amount in child transaction": "无法覆盖子交易中的金额。",
    "Cannot override currency in child transaction": "无法覆盖子交易中的货币。",
    "Subscription requires RECUR account": "订阅需要设置定期账户。",
    "Subscription requires successful parent": "订阅需要成功的父交易。",
    "Risk Decisions must have AUTH as parent": "风险决策必须与授权交易相关联。",
    "Chargebacks must have AUTH/REFUND as parent": "拒付必须与授权或退款交易相关联。",
    "Refund amount less than Minimum allowed": "退款金额小于允许的最低金额。",
    "Refund requires paypaltransactionid": "退款需要 PayPal 交易 ID。",
    "Invalid split transaction": "分拆交易无效。",
    "Cannot reverse AUTH processed more than 48 hours ago": "无法撤销超过 48 小时的授权。",
    "Reversal requires acquirerreferencedata": "撤销需要收购方参考数据。",
    "Cannot reverse AUTH processed by a different acquirer": "无法撤销由不同收购方处理的授权。",
    "Payment type does not support repeats": "付款类型不支持重复交易。",
    "Reversal missing required data": "撤销缺少所需数据。",
    "Missing token": "缺少令牌信息。",
    "Subscription with an accountcheck parent not supported on current acquirer": "当前收购方不支持检查账户的父交易的订阅。",
    "Subscription cannot be used as a parent": "订阅不能用作父交易。",
    "Invalid parent": "父交易类型无效。",
    "Payment type does not support refunds": "支付类型不支持退款。",
    "Invalid incremental transaction": "增量交易无效。",
    "Partial reversals not supported": "不支持部分撤销。",
    "THREEDQUERY parent/child must have the same payment type as the child": "父交易和子交易必须具有相同的支付类型以进行 3D 安全查询。",
    "Payment type does not support card scheme updates": "支付类型不支持卡方案更新。",
    "Cannot reverse AUTH at this time, please try again": "此时无法撤销授权。请稍后再试。",
    "Cannot determine token": "无法确定令牌。请验证并重试。",
    "Service Temporarily Disabled": "服务暂时停用。请稍后再试。",
    "Login first request": "在发出请求前需要登录。",
    "Invalid username/password": "用户名或密码无效。请重试。",
    "Invalid session": "会话无效。请重新登录。",
    "Session has expired": "会话已过期。请重新登录。",
    "Password expired": "密码已过期。请重置密码。",
    "Password has been previously used": "密码已被之前使用过。请选择新密码。",
    "MyST user account has been locked": "MyST 用户账户已锁定。请联系支持。",
    "New password does not match confirmed password": "新密码与确认密码不匹配。",
    "Incorrect current password": "当前密码不正确。请重试。",
    "Invalid selection": "选择无效。请选择一个有效的选项。",
    "User already exists": "用户已存在。请登录或在需要时重置密码。",
    "No transaction found": "未找到交易。",
    "Invalid selected transactions": "选中的交易无效。",
    "Data supplied has not been saved": "数据未保存。请重试。",
    "Invalid request type": "请求类型无效。",
    "Missing request type, at least one request type must be selected": "缺少请求类型。必须至少选择一个。",
    "Invalid payment type": "支付类型无效。",
    "Missing payment type, at least one payment type must be selected": "缺少支付类型。必须至少选择一个。",
    "Invalid error code": "错误代码无效。",
    "Missing error code, at least one error code must be selected": "缺少错误代码。必须至少选择一个。",
    "Invalid filter description": "过滤器描述无效。",
    "Invalid destination description": "目的地描述无效。",
    "Invalid notification type": "通知类型无效。",
    "Invalid destination": "目的地无效。",
    "Invalid field selected": "选中的字段无效。",
    "Invalid email from address": "发送方的电子邮件地址无效。",
    "Invalid email subject": "电子邮件主题行无效。",
    "Invalid email type": "电子邮件类型无效。",
    "Unable to process request": "无法处理请求。请稍后再试。",
    "No file selected for upload": "未选择上传文件。",
    "Invalid file size": "文件大小无效。请提供一个可接受大小的文件。",
    "Invalid filename": "文件名无效。",
    "Invalid extension": "文件扩展名无效。",
    "User requires at least one site reference": "用户需要至少一个站点引用。",
    "Only ST-level users can have '*' access": "仅 ST 级用户可以拥有 '*' 访问权限。",
    "Request failed": "请求失败。请稍后再试。",
    "Invalid File Contents": "文件内容无效。",
    "Maximum number of files uploaded": "上传的文件数量达到最大值。请删除一些文件并重试。",
    "Insufficient gateway access privileges": "网关访问权限不足。",
    "Maximum file size limit reached": "达到文件大小的最大限制。",
    "Username(s) must be a valid user(s)": "用户名必须是有效用户。",
    "Site reference(s) must be a valid site(s)": "站点引用必须是有效站点。",
    "Unable to send email, please verify the details and try again": "无法发送电子邮件。请验证详细信息并重试。",
    "Negative already exists": "已存在负值。请验证并进行更正。",
    "Cannot delete a search owned by another user": "无法删除其他用户拥有的搜索。",
    "Invalid search": "搜索无效。请尝试使用有效的条件。",
    "Cannot delete the specified search, the search name cannot be found": "无法删除指定的搜索。找不到搜索名称。",
    "Search parameter is too short": "搜索参数太短。请输入更具体的搜索词。",
    "Duplicate custom fields defined": "定义了重复的自定义字段。请解决重复项。",
    "Cannot allocate selected users, insufficient privileges": "无法分配选定的用户。权限不足。",
    "Allocated users have access to additional sites": "分配的用户可以访问其他站点。",
    "Allocated users have access to additional users": "分配的用户可以访问其他用户。",
    "User with current role cannot be allocated users": "当前角色的用户无法分配其他用户。",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "此站点要求您的浏览器接受 cookie 以登录。可以通过点击“我接受”来接受 cookie。",
    "User requires at least one site reference or site group": "用户需要至少一个站点引用或站点组。",
    "Allocated users have access to additional site groups": "分配的用户可以访问其他站点组。",
    "No statement found": "未找到声明。",
    "Data supplied has not been updated in MobilePay 3rd-party service": "在 MobilePay 第三方服务中未更新提供的数据。",
    "Bypass": "绕过",
    "Insufficient access privileges": "权限不足。",
    "Coding error": "检测到编码错误。",
    "Insufficient privileges": "执行此操作的权限不足。",
    "Invalid request": "请求无效。请验证并重试。",
    "Invalid field": "输入字段无效。请验证并更正。",
    "Unknown site": "未知站点。",
    "Banned card": "被禁止的卡。",
    "XML element parse error": "XML元素解析错误。请验证XML结构。",
    "Maestro must use SecureCode": "Maestro交易必须使用SecureCode进行验证。",
    "Multiple email addresses must be separated with , or ;": "多个电子邮件地址必须用逗号（,）或分号（;）分隔。",
    "Invalid site reference for alias": "别名的站点引用无效。",
    "Invalid version number": "版本号无效。请输入有效的版本号。",
    "Unknown user": "未知用户。请验证并重试。",
    "Cannot determine account": "无法确定账户。",
    "JSON element parse error": "JSON元素解析错误。请验证JSON结构。",
    "Wallet type configuration error": "钱包类型配置错误。",
    "Wallet type not supported on this request": "请求不支持的钱包类型。",
    "The card number you have provided is incorrect, please verify your details and try again": "提供的卡号不正确。请验证您的详细信息并重试。",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "您提供的安全代码（CVV2）不正确。请验证并重试。",
    "The expiry date you have provided is incorrect, please verify your details and try again": "提供的到期日期不正确。请验证您的详细信息并重试。",
    "The expiry month you have provided is incorrect, please verify your details and try again": "提供的到期月份不正确。请验证并重试。",
    "The expiry year you have provided is incorrect, please verify your details and try again": "提供的到期年份不正确。请验证并重试。",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "由于连接错误，无法处理您的付款。请求ID不匹配。请重试。",
    "The issue number you have provided is incorrect, please verify your details and try again": "提供的期号不正确。请验证并重试。",
    "The payment type you have provided is incorrect, please verify your details and try again": "提供的付款类型不正确。请验证并重试。",
    "Unable to process your payment, please contact the website": "无法处理您的付款。请联系网站支持。",
    "There are errors with these fields: {0}": "这些字段中存在错误：{0}",
    "No account found": "未找到账户。",
    "Refund cannot be processed": "无法处理退款。",
    "Transaction de-activated": "交易已停用。",
    "Socket receive error": "套接字接收错误。",
    "Socket connection error": "套接字连接错误。",
    "Socket closed": "套接字已关闭。",
    "Invalid data received": "收到的数据无效。",
    "Invalid SQL": "无效的SQL查询。",
    "Timeout": "发生超时。",
    "Invalid acquirer": "收单方详细信息无效。",
    "Unable to connect to acquirer": "无法连接到收单方。",
    "Invalid response from acquirer": "收单方响应无效。",
    "No available transport": "找不到可用的传输。",
    "File size too large": "文件大小过大。",
    "Socket send error": "套接字发送错误。",
    "Communication error": "通信错误。",
    "Proxy error": "代理错误。",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "由于连接错误，无法处理您的付款。请验证您的详细信息并重试（{0}）。",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "由于连接错误，无法处理您的付款（HTTP响应状态{0}）。请验证您的详细信息并重试（{1}）。",
    "Wrong number of emails": "提供的电子邮件地址数量不正确。",
    "Bank System Error": "银行系统错误。",
    "Wrong number of transactions": "提供的交易数量不正确。",
    "Invalid transport configuration": "传输配置无效。",
    "No valid updates specified": "未指定有效的更新。",
    "Transaction reference not found": "找不到交易参考。",
    "Settlebaseamount too large": "结算基础金额过大。",
    "Transaction not updatable": "交易不可更新。",
    "No searchable filter specified": "未指定可搜索的过滤器。",
    "Timeout Error": "超时错误。请重试。",
    "3-D Secure Transport Error": "3-D Secure传输错误。",
    "Unauthenticated": "未经认证的请求。",
    "Site Suspended": "站点已暂停。",
    "No updates performed": "未执行任何更新。",
    "Invalid Request": "请求无效。",
    "Invalid Response": "响应无效。",
    "Invalid Acquirer": "收单方信息无效。",
    "Invalid account data": "账户数据无效。",
    "Missing": "信息缺失。",
    "Payment Error": "支付错误。",
    "Invalid acquirer for 3-D Secure": "收单方对于3-D Secure无效。",
    "Invalid payment type for 3-D Secure": "支付类型对于3-D Secure无效。",
    "Invalid updates specified": "指定的更新无效。",
    "Manual investigation required": "需要手动调查。",
    "Invalid headers": "无效的头文件。",
    "Max fraudscore exceeded": "超过最大欺诈评分。",
    "Invalid filters": "提供的过滤器无效。",
    "Merchant System Error": "商户系统错误。",
    "Your payment is being processed. Please wait...": "您的付款正在处理中。请稍候...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "不能在单个请求中同时指定'请求类型描述'和'请求类型描述集'。",
    "Acquirer missing original transaction data": "收单方缺少原始交易数据。",
    "Insufficient funds": "资金不足。",
    "Unable to process due to scheme restrictions": "由于计划限制，无法处理。",
    "Failed Screening": "筛选失败。",
    "Unable to process due to restrictions": "由于限制，无法处理。",
    "Invalid process": "无效的流程。",
    "Risk Referral": "需要风险推荐。",
    "Name Pick required": "选择名称是必需的。",
    "Address Pick required": "选择地址是必需的。",
    "IP not in range": "IP地址不在范围内。",
    "Invalid button configuration": "按钮配置无效。",
    "Unrecognised response from acquirer": "无法识别收单方的响应。",
    "Decline": "拒绝。",
    "Uncertain result": "结果不确定。",
    "Soft Decline": "软拒绝。",
    "Refer to Issuer": "请联系发卡机构了解更多信息。",
    "Request is queued please check the transaction later for the status": "请求已排队。请稍后检查交易状态。",
    "Generic Retry": "通用重试。",
    "Soft Decline retry": "软拒绝重试。",
    "There has been a problem with your payment, please verify your details and try again": "您的付款出现问题。请验证您的详细信息并重试。",
    "Unknown error": "未知错误。",

    "Login": "登录",
    "Enter Your Email": "输入您的电子邮件",
    "Login With Phone Number": "使用电话号码登录",
    "Verify Using Sms/Email": "使用短信/电子邮件验证",
    "Resend Code In 100 Seconds": "在100秒内重新发送验证码",
    "Verify": "验证",
    "Verifying": "正在验证",
    "Last Week": "上周",
    "Older Transaction": "较早的交易",
    "Total": "总计",
    "Conversion Confirmed": "转换已确认",
    "User Account Limit": "用户账户限制",
    "Monthly Balance Limit": "每月余额限制",
    "Total Limit": "总限制",
    "Used": "已使用",
    "Remaining": "剩余",
    "Allowed Minimum Topup Amount": "允许的最小充值金额",
    "Allowed Maximum Topup Amount": "允许的最大充值金额",
    "Incoming Limit": "收入限制",
    "Daily limit": "日限额",
    "Monthly limit": "月限额",
    "Yearly limit": "年限额",
    "Sending Limit": "发送限制",
    "Allowed Number Of Transactions": "允许的交易数量",
    "Total Amount": "总金额",
    "Amount To Send": "要发送的金额",
    "Destination Amount": "目的金额",
    "Note": "备注",
    "Revised Amount": "修改后的金额",
    "Save": "保存",
    "Date": "日期",
    "Action": "操作",
    "Payment Scheduled At": "计划支付时间",
    "Transaction Fee": "交易费用",
    "Number Of Cycle": "循环次数",
    "Subscription Starting Date": "订阅开始日期",
    "Until": "直到",
    "Next Transaction": "下一个交易",
    "Pay Now": "立即支付",
    "Transaction Method": "交易方式",
    "Mobile Money Provider": "移动支付提供商",
    "Wallet Name": "钱包名称",
    "Wallet Number": "钱包号码",
    "Crypto Currency": "加密货币",
    "Wallet Address": "钱包地址",
    "Select Document Type": "选择文档类型",
    "Edit Document Number": "编辑文档号码",
    "My Withdrawals": "我的提款",
    "Bargainable": "可议价",
    "Description": "描述",
    "Attached Files": "附件文件",
    "Sent": "已发送",
    "Accepted": "已接受",
    "Decline": "已拒绝",
    "Pending": "待处理",
    "Overall Rating": "综合评分",
    "No Review": "无评论",
    "0 Rating": "0评分",
    "Transaction Map": "交易地图",
    "Send Money": "发送钱款",
    "Sending Method": "发送方式",
    "Trouble Sending Money": "发送钱时出问题",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "根据选择的支付方式，费用和收到的金额可能会有所不同。",
    "Select Method": "选择方法",
    "Completed": "完成",
    "How Was Your Experience?": "您的体验如何？",
    "Leave A Review": "留下评论",
    "Review History": "评论历史",
    "Write A Review": "写评论",
    "Send And Post": "发送并发布",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out 加密货币到法定货币的转换是一种强大而安全的解决方案，可以无缝地将加密货币转换为法定货币。它满足用户的各种需求，提供根据个人喜好和地点量身定制的多种支付渠道。\"",
    "Review Created": "审核已创建",
    "Transactions": "交易",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "拖动/上传文件（照片/视频/PDF）",
    "Max Size 1GB": "最大大小 1GB",
    "Title Description": "标题描述",
    "Add New": "添加新内容",
    "Discard": "丢弃",
    "Save And Post": "保存并发布",
    "Personal Account": "个人账户",
    "Conversion": "转换",
    "Spent In Total": "总共花费",
    "International": "国际",
    "Recharge": "充值",
    "Top Up Wallet To Wallet": "钱包到钱包充值",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay钱包",
    "Not Connected": "未连接",
    "More": "更多",
    "Less": "更少",
    "Profile Reports": "个人资料报告",
    "Timezone": "时区",
    "Cancel": "取消",
    "Full Name": "全名",
    "Google Authenticator": "Google认证器",
    "Documents Supplémentaires": "附加文件",
    "Profile Complete": "资料完整",
    "Verified": "已验证",
    "Bank Account": "银行账户",
    "Mobile Wallet": "移动钱包",
    "Cash Pickup": "现金提取",
    "Add": "添加",
    "Configured": "已配置",
    "Email": "电子邮件",
    "Download Data": "下载数据",
    "Enter The Amount": "输入金额",
    "They Receive": "他们收到",
    "Where Do You Want To Send Money": "你想把钱寄到哪里？",
    "Bank Transfer": "银行转账",
    "Receiving Method": "接收方法",
    "Payer": "付款人",
    "Minimum Amount": "最低金额",
    "Maximum Amount": "最大金额",
    "Search": "搜索",
    "Show More": "显示更多",
    "Select Remark / Reasons (All)": "选择备注/原因（全部）",
    "Attach Files": "附件",
    "Holiday List": "假期列表",
    "No Data To Show": "没有数据可显示",
    "Threshold Limits": "阈值限制",
    "Amount Limits": "金额限制",
    "OTP Verification Code": "OTP 验证码",
    "Verify Using Authenticator": "使用认证器验证",
    "Sms/Email Verification Code": "短信/电子邮件验证代码",
    "Invite Them": "邀请他们",
    "Lookup": "查找",
    "Invite Friend": "邀请朋友",
    "Invite Via Phone Number": "通过电话号码邀请",
    "Invite Via Email Address": "通过电子邮件地址邀请",
    "Next": "下一步",
    "Back": "返回",
    "Invite Via Email": "通过电子邮件邀请",
    "Available Currency": "可用货币",
    "Add Remarks And Comments": "添加备注和评论",
    "Recipient Details": "收件人详情",
    "Go Back": "返回",
    "Trouble Requesting Money": "请求资金时遇到问题",
    "Instant": "即时",
    "Subscription": "订阅",
    "Schedule": "时间表",
    "Choose Request Type": "选择请求类型",
    "Mobile Airtime": "移动话费",
    "Incorrect Phone Number": "错误的电话号码",
    "Make Sure You Have Entered A Correct Phone Number.": "确保您输入了正确的电话号码。",
    "Close": "关闭",
    "Operator": "操作员",
    "Recipient Mobile Number": "收款人手机号码",
    "Amount": "金额",
    "Service": "服务",
    "Destination": "目的地",
    "Top Up Summary": "充值总结",
    "Pakistan": "巴基斯坦",
    "Daily Sending Limit Exceeded": "超过每日发送限额",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "向您的客户或买家发送详细的报价单。用个性化的报价专业地展示您的服务、产品或优惠。",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "在工作日3:00 PM之前进行的付款将在当天午夜前存入接收银行账户。",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "设置您的接收账户，在最多三个国家，并立即将批准的付款请求中的资金提现到您已激活的支付渠道。",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom 保留根据其法律义务请求任何额外文件（增强尽职调查 - EDD）或进行彻底验证所需的进一步信息的权利。",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "必须是政府颁发的带照片的身份证明文件。所有身份证明文件必须使用拉丁字母书写。如果不是，则需要认证翻译。",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "展示您的作品，扩大您的影响力。 '我的作品集' 是您在 Instapay 上的数字画布。上传并展示各种内容 - 从引人入胜的图像和视频到信息丰富的 PDF。无论您是艺术家、自由职业者还是企业家， 这个功能让您以视觉吸引的方式展示您的作品或项目。",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "分享您的二维码或支付链接，立即在您的 Insta 钱包中获得付款。"
}