export const ha = {
    "How Others Can Find You": "Yaya sauran suke iya ka gano",
    "Schedules": "Mafiɗi",
    "Login": "Shiga",
    "Start Your InstaPay Journey Today!": "Fara Tafiyarka ta InstaPay Yau!",
    "Phone Number or Email": "Lambar Waya ko Imel",
    "Login Using Social Media": "Shiga da Amfani da Kafofin Sada Zumunta",
    "Continue": "Ci gaba",
    "Don't Have an Account? Sign Up": "Baka da Asusun? Yi Rijista",
    "Sign Up": "Yi Rijista",
    "Edit": "Shirya",
    "Enter Your Password": "Shigar da Kalmar Sirrinka",
    "Forgot Password?": "Manta Kalmar Sirri?",
    "Account Type": "Nau'in Asusun",
    "Individual": "Mutum",
    "Business": "Kasuwanci",
    "Already Have an Account? Sign In": "Kana da Asusun? Shiga",
    "Sing In": "Shiga",
    "Enter Your Personal Details": "Shigar da Bayananka na Kai",
    "Add Photo or Logo": "Ƙara Hoto ko Tambari",
    "Enter Your Name": "Shigar da Sunanka",
    "Back": "Komawa",
    "Phone Number": "Lambar Waya",
    "Email Address": "Adireshin Imel",
    "Create a Strong Password": "Ƙirƙiri Kalmar Sirri Mai Ƙarfi",
    "Password Requirements": "Bukatun Kalmar Sirri",
    "Must be between 9 and 64 characters": "Dole ne tsakanin haruffa 9 zuwa 64",
    "Include at least two of the following:": "Haɗa aƙalla biyu daga cikin waɗannan:",
    "Uppercase character": "Harafin Babba",
    "Lowercase character": "Harafin Ƙarami",
    "Number": "Lambar",
    "Special character": "Harafin Musamman",
    "Confirm Password": "Tabbatar da Kalmar Sirri",
    "Finalize Your Process": "Kammala Tsarinka",
    "Referral Code (Optional)": "Lambar Shawarwari (Zabi)",
    "It will autofill if you're signing up with an invitation link.": "Zai cika ta atomatik idan kana yin rijista da hanyar haɗin gayyata.",
    "I agree with": "Na yarda da",
    "Terms & Conditions": "Sharudda & Yanayi",
    "Privacy Policy": "Manufar Sirri",
    "Create Your Account": "Ƙirƙiri Asusunka",
    "Enter Code": "Shigar da Lambar",
    "A one-time verification code has been sent to your email address  and phone number.": "An aika lambar tabbatarwa sau ɗaya zuwa adireshin imel ɗinka da lambar waya.",
    "Resend available in ": "Za a iya sake aikawa a cikin",
    "seconds": "daƙiƙa",
    "Verify": "Tabbatar",
    "Verification Code Verified": "An Tabbatar da Lambar Tabbatarwa",
    "Please continue to the next step.": "Da fatan za a ci gaba zuwa mataki na gaba.",
    "Finish": "Gama",
    "Finishing": "Ƙarewa",
    "Company Name": "Sunan Kamfani",
    "Enter Your Business Details": "Shigar da Bayanan Kasuwancinka",
    "Country ": "Ƙasa",
    "You cannot change the country once your account is verified.": "Ba za ka iya canza ƙasar da zarar an tabbatar da asusunka ba.",
    "Learn more": "Ƙarin koyo",
    "Optional": "Zabi",
    "Continuing": "Ci gaba",
    "Didn't receive the verification code?": "Ba ka karɓi lambar tabbatarwa ba?",
    "Resend Now": "Aika Yanzu",
    "Search Here": "Bincika Anan",
    "Search for users, services, and more on InstaPay": "Nemi masu amfani, ayyuka, da ƙari akan InstaPay",
    "Search users here": "Nemi masu amfani anan",
    "Earn money with our affiliate program!": "Samu kuɗi tare da shirinmu na haɗin gwiwa!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Samu kuɗin shiga na rashin aiki ta hanyar raba lambar haɗin gwiwar InstaPay ɗinku tare da mabiyanku. Ga kowane ma'amala da suka yi,\nza ku samu kwamiti, ta hakan ku ƙara yawan ribar da kuke samu daga tasirinku akan layi.",
    "Learn More": "Ƙarin Koyo",
    "Balance Amount": "Adadin Kuɗi a Ma'auni",
    "You spent less compared to last month.": "Ka kashe ƙasa idan aka kwatanta da watan da ya gabata.",
    "Referral Rewards": "Kyaututtukan Shawarwari",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Saurin Canja wurin zuwa Lambobin Tuntuɓarku Na Baya-bayan nan:",
    "Number of Transactions": "Yawan Ma'amaloli",
    "Total Transaction Amount": "Jimlar Adadin Ma'amala",
    "Total Sent": "Jimlar Aika",
    "Total Received": "Jimlar Karɓa",
    "Add Funds": "Ƙara Kuɗi",
    "Send Money": "Aika Kuɗi\n",
    "Request Money": "Neman Kuɗi",
    "Send a Quote": "Aika Fa'ida",
    "Pay With Crypto": "Biya da Crypto",
    "Payment Insights": "Haske akan Biyan Kuɗi",
    "Recent Transactions ": "Ma'amaloli na Kwanan nan",
    "Today": "Yau",
    "My Wallets": "Wallets ɗina",
    "Balance": "Ma'auni",
    "Wallet ID": "Wallet ID",
    "Monthly limit": "Iyakar Wata-wata",
    "Top Up": "Ƙara Kuɗi",
    "Summary": "Takaitawa",
    "Settings": "Saituna",
    "More": "Ƙari",
    "Upgrade to Business Account": "Haɓaka zuwa Asusun Kasuwanci",
    "Conversion": "Musayar",
    "Enter Amount": "Shigar da Adadi",
    "Exchanged Amount": "Adadin da Aka Musanya",
    "Convert": "Musanya",
    "You Are Converting": "Kuna Musanya",
    "From": "Daga",
    "To": "Zuwa",
    "Exchange Rate": "Ƙimar Musayar",
    "Fees": "Kuɗi",
    "You Will Receive": "Za Ku Karɓa",
    "Slide To Confirm": "Matsa Don Tabbatarwa",
    "Verification Code Confirmed - You'll be redirected to the next step": "An Tabbatar da Lambar Tabbatarwa - Za a maida ku zuwa mataki na gaba",
    "Moving to next step in": "Ana matsawa zuwa mataki na gaba a cikin",
    "Thanks for using InstaPay": "Na gode da amfani da InstaPay",
    "Your funds have been credited to your wallet.": "An saka kuɗin ku a cikin wallet ɗinku.",
    "Dashboard": "Allon Sarrafa",
    "Accounts": "Asusun",
    "Transactions": "Ma'amaloli",
    "Other Transactions": "Sauran Ma'amaloli",
    "Payments": "Biyan kuɗi",
    "Beneficiaries": "Masu Amfana",
    "Referrals": "Shawarwari",
    "Quotations": "Ƙididdiga",
    "Pending": "Ana Jira",
    "My QR Code Sticker": "Tambarin Lambar QR ɗina",
    "My Portfolio": "Fayil ɗina",
    "My Payment Address": "Adireshin Biyan Kuɗina",
    "Analytics": "Bincike",
    "Profile": "Bayanin Kai",
    "Dark Mode": "Yanayin Duhu",
    "Support": "Taimako",
    "Logout": "Fita",
    "Powered By": "Ƙarfafawa ta",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "A Swiss Company",
    "MAIN MENU": "MAIN MENU",
    "OTHERS": "OTHERS",
    "Wallet": "Wallet",
    "Default": "Default",
    "Breakdown": "Breakdown",
    "Credit": "Credit",
    "Debit": "Debit",
    "Wallet Management": "Wallet Management",
    "Top-up Your Wallet": "Top-up Your Wallet",
    "Download Statement": "Download Statement",
    "Block the Wallet": "Block the Wallet",
    "Wallet Status": "Wallet Status",
    "-Select-File-Type-": "-Select-File-Type-",
    "Download Now": "Download Now",
    "Downloading": "Downloading",
    "All": "All",
    "Sent": "Sent",
    "Received": "Received",
    "Requested": "Requested",
    "Quoted": "Quoted",
    "Search Transactions": "Search Transactions",
    "Date & Time": "Date & Time",
    "Type": "Type",
    "Transaction ID": "Transaction ID",
    "Recipient": "Recipient",
    "Amount": "Amount",
    "Status": "Status - Matsayi",
    "Payment Type": "Payment Type",
    "Sent Payments": "Sent Payments",
    "Received Payments": "Received Payments",
    "Date": "Date",
    "Cycles/Time": "Cycles/Time",
    "Payment As": "Payment As",
    "Name": "Name",
    "Cancel": "Cancel",
    "Subscriptions & Scheduled": "Subscriptions & Scheduled",
    "Select the option that matches your needs from the list below:": "Select the option that matches your needs from the list below:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Sauƙaƙe aika kuɗi ga abokai, iyali, ko kasuwanci a gida ko ƙasashen waje. Zaɓi daga zaɓuɓɓukan canja wurin daban-daban, ciki har da \najiyar banki, wallets na wayar hannu, wurin ɗaukar kuɗi, katunan biyan kuɗi, da canja wurin wallet-zuwa-wallet. Ji daɗin sauƙi da tsaron \nInstaPay don canja wurin kuɗi cikin sauri da sauƙi.",
    "International Transfer": "Canja Wuri na Ƙasa da Ƙasa",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Aika kuɗi zuwa ƙetare cikin sauƙi. Canja wurin kuɗi na ƙasa da ƙasa ga abokai, iyali, ko kasuwanci cikin dannawa kaɗan. Zaɓi daga\nfannoni masu yawa na biyan kuɗi ciki har da asusun banki, kuɗin hannu/wallet na wayar hannu, wurin ɗaukar kuɗi, ko katin biyan kuɗi\ndon ma'amaloli masu dacewa da tsaro.",
    "Select the country where you want to send the money!": "Zaɓi ƙasar inda kake son aika kuɗin!",
    "Search Country": "Bincika Ƙasa",
    "Trouble Sending Money?": "Matsala wajen Aika Kuɗi?",
    "Confirm": "Tabbatar",
    "Select Payout Channel": "Zaɓi Hanyar Biyan Kuɗi",
    "Select your most convenient option to send the money.": "Zaɓi zaɓin da ya fi sauƙi a gare ka don aika kuɗin.",
    "Select The Bank": "Zaɓi Bankin",
    "Search Bank": "Bincika Banki",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Aika kuɗi kai tsaye zuwa asusun bankin mai karɓa. Ji daɗin sauƙi da tsaro na canja wurin kuɗi cikin santsi zuwa kowane banki a faɗin duniya.",
    "Bank Transfer": "Canja wurin Banki",
    "Enter the amount": "Shigar da adadin",
    "Tax": "Haraji",
    "Amount the recipient will receive": "Adadin da mai karɓa zai samu",
    "Select Remarks / Reason": "Zaɓi Bayanai / Dalili",
    "Comments": "Sharhi",
    "Attach Files": "Haɗa Fayiloli",
    "Groups": "Ƙungiyoyi",
    "Manage Groups": "Sarrafa Ƙungiyoyi",
    "Add New Group": "Ƙara Sabuwar Ƙungiya",
    "Invite": "Gayyatar Mutum",
    "Manage Beneficiaries": "Sarrafa Masu Amfana",
    "Recipients List": "Jerin Masu Karɓa",
    "Search Contacts": "Bincika Lambobin Sadarwa",
    "Confirm Your Payment": "Tabbatar da Biyan Kuɗinka",
    "Select Country": "Zaɓi Ƙasa",
    "Select the Recipient's Payment Channel": "Zaɓi Hanyar Biyan Kuɗi ta Mai Karɓa",
    "Change Selection": "Canja Zaɓi",
    "You Are Transferring ": "Kuna Canja wurin",
    "Oops! Something Went Wrong.": "Ayya! Wani Abu ya Samu Matsala.",
    "We're Sorry About That. ": "Muna da Hakuri game da Hakan.",
    "Go back to Payments Page.": "Koma zuwa Shafin Biyan Kuɗi.",
    "Wallet to Wallet Transfer": "Canja wurin Wallet zuwa Wallet",
    "Request a customized price quote from service providers or vendors.": "Nemi ƙimar farashi na musamman daga masu samar da ayyuka ko 'yan kasuwa.",
    "Enter the Wallet ID": "Shigar da Wallet ID",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Shigar da Wallet ID, Imel, ko Lambar Waya ta mai karɓa.",
    "Recipient not on InstaPay?": "Shin Mai Karɓan Ba Ya Kan InstaPay? Gayyace Su.",
    "Invite Them": "Gayyace Su",
    "My Beneficiaries": "Masu Amfanina",
    "Enter the Amount You Wish to Transfer.": "Shigar da Adadin da Kake Son Canja wurin.",
    "Sending to ": "Ana aikawa zuwa",
    "You Have Selected Wallet to Wallet Transfer": "Ka Zaɓi Canja wurin Wallet zuwa Wallet",
    "Authenticate Your Payment.": "Tabbatar da Biyan Kuɗinka.",
    "You are transferring": "Kuna canja wurin",
    "Total ": "Jimla",
    "Where Is My Payment?": "Ina Biyana yake?",
    "Share": "Raba",
    "Your Payment Is Confirmed": "An Tabbatar da Biyan Kuɗinka",
    "QR Code Payment": "Biyan Kuɗi ta QR Code",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Canja wurin kuɗi nan take zuwa wasu masu amfani da InstaPay tare da sabis ɗinmu na wallet-to-wallet mai aminci. Aika da karɓar kuɗi cikin sauƙi \nba tare da wata matsala ba cikin daƙiƙoƙi.",
    "Scan QR with Your Camera or Upload from Your Device.": "Sken QR tare da Kyamararka ko Aiko daga Na'urarka.",
    "Upload from Your Device.": "Aiko daga Na'urarka.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Ko kuma, Yi Amfani da Adireshin Biyan Kuɗi na Musamman na InstaPay (UPA)",
    "Enter InstaPay UPA": "Shigar da InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Shin Mai Karɓan Ba Ya Kan InstaPay? Gayyace Su",
    "Verify QR Code": "Tabbatar da QR Code",
    "Scan/Upload QR Code of the Recipient": "Sken/Upload QR Code na Mai Karɓa",
    "Payment Confirmed": "An Tabbatar da Biyan",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Nemi ƙididdiga na musamman daga masu samar da ayyuka ko 'yan kasuwa don samun ƙimar kuɗi madaidaiciya ga ayyukan ko samfuran da kuke buƙata.",
    "Choose The Beneficiary or Group": "Zaɓi Mai Amfana ko Ƙungiya",
    "Enter the Amount You Wish to Request.": "Shigar da Adadin da Kake Son Nema.",
    "You Are Requesting Money": "Kuna Neman Kuɗi",
    "You are requesting": "Kuna neman",
    "As": "A Matsayin",
    "Conversion of Your Cryptocurrencies": "Musayar Kuɗaɗen Ku na Cryptocurrency",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ku fuskanci tsarinmu mai amfani da mai amfani, aminci, da inganci na Cash-Out Crypto zuwa Musayar Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Zaɓi Cryptocurrency da Kake Nufin Sayarwa.",
    "Next": "Na Gaba",
    "Enter the Crypto Wallet Address of the Recipient.": "Shigar da Adireshin Wallet na Crypto na Mai Karɓa.",
    "Minimum Transactional Value: $100.": "Ƙimar Ma'amala Mafi Ƙaranci: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Lura: Farashin Crypto na canjawa da yawa. Duba ƙididdigar ƙarshe a shafin tabbatar da oda.",
    "Select Network": "Zaɓi Hanyar Sadarwa",
    "Send A Quote": "Aika Fa'ida",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Aika ƙididdigar farashi ta cikakke ga abokan cinikinku ko kwastomomin ku. Gabatar da ayyukanku, samfuran, ko tayi cikin ƙwarewa tare da ƙididdiga ta musamman.",
    "Choose the Beneficiary": "Zaɓi Mai Amfana",
    "Allow Receiver to Bargain": "Bada Dama ga Mai Karɓa ya Tattauna",
    "Enter the Title for Your Quotation.": "Shigar da Take don Ƙididdigarka.",
    "Enter the Description for Your Quotation": "Shigar da Bayanin Ƙididdigarka",
    "You are Quoting": "Kuna Yiwa Ƙididdiga",
    "Title": "Take",
    "Description": "Bayani",
    "Attachments": "Haɗe-haɗe",
    "Confirm Your Quotation": "Tabbatar da Ƙididdigarka",
    "Verify The Quotation": "Tabbatar da Ƙididdiga",
    "Your quotation is confirmed": "An tabbatar da ƙididdigarku",
    "Request A Quote": "Nemi Kiyasi",
    "Coming Soon": "Ana Zuwa Nan Ba Da Jimawa Ba",
    "Add Beneficiary": "Ƙara Mai Amfana",
    "Search for Beneficiaries": "Neman Masu Amfana",
    "Connected Accounts": "Asusun da Aka Haɗa",
    "Details": "Bayanai",
    "First Name": "Sunan Farko",
    "Last Name": "Sunan Karshe",
    "Country": "Ƙasa",
    "City": "Birni",
    "Address Line": "Layin Adireshin",
    "Mobile Number": "Lambar Waya",
    "Relationship With Beneficiary": "Dangantaka da Mai Amfana",
    "Bank Account": "Asusun Banki",
    "Mobile Money/Mobile Wallet": "Kuɗin Waya/Mobile Wallet",
    "Payment Card": "Katin Biyan Kuɗi",
    "Crypto Wallet": "Wallet na Crypto",
    "InstaPay Account": "Asusun InstaPay",
    "Cash Pickup": "Daukar Kudi",
    "IBAN / SWIFT Code": "IBAN Ko Lambar Swift/BIC",
    "Swift Code": "Lambar Swift",
    "Account Number": "Lambar Asusun",
    "Bank Name": "Sunan Banki",
    "Branch Name": "Sunan Reshe",
    "Branch Street": "Titin Reshe",
    "Province": "Lardin",
    "Postal Code": "Lambar Fosta",
    "Mobile Money Provider": "Mai Bayar da Kuɗin Waya",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number": "Lambar Wallet",
    "Card Holder Name": "Sunan Mai Riƙe da Kati",
    "Card Number": "Lambar Kati",
    "Expiry Date": "Ranar Karewa",
    "Crypto Currency": "Kuɗin Crypto",
    "Wallet Address": "Adireshin Wallet",
    "Wallet Holder Name": "Sunan Mai Riƙe da Wallet",
    "Wallet Currency": "Kuɗin Wallet",
    "Select Document Type": "Zaɓi Nau'in Takarda",
    "Enter Document Number": "Shigar da Lambar Takarda",
    "Add Individual Account": "Ƙara Asusun Mutum",
    "Add Business Account": "Ƙara Asusun Kasuwanci",
    "Company Address": "Adireshin Kamfani",
    "Company Email": "Imel na Kamfani",
    "Company Phone No": "Lambar Wayar Kamfani",
    "Total Referrals": "Jimlar Shawarwari",
    "My Earnings": "Kudin Shigana",
    "Your Referral ID": "ID na Tura Kai",
    "Share Your Unique Referral Link": "Raba Mahaɗin Tura Kai na Musamman",
    "How do I Refer a Friend to InstaPay?": "Yaya Zan Tura Aboki zuwa InstaPay?",
    "Step 01": "Mataki na 01",
    "Share your InstaPay unique referral link with your friends.": "Raba mahaɗin tura kai na musamman na InstaPay tare da abokanka.",
    "Step 02": "Step 02 - Mataki na 02",
    "Earn rewards when they transact $100 or more": "Samu lada idan suka yi mu'amala da $100 ko fiye",
    "Step 03": "Step 03 - Mataki na 03",
    "Earn up to $0.06 for every transaction your followers make.": "Samu har zuwa $0.06 ga kowane mu'amala da mabiyanka suka yi.",
    "My Referrals": " Na Tura Kai",
    "Referral Complete": "An Kammala Tura Kai",
    "Received Quotations": "An Karɓi Ƙididdiga",
    "Sent Quotations": "An Aika Ƙididdiga",
    "Search Pending Items": "Bincika Abubuwan Jiran",
    "Actions": "Matakan Aiki",
    "Received Requests": "An Karɓi Bukatun",
    "Sent Requests": "An Aika Bukatun",
    "No Actions": "Babu Matakan Aiki",
    "You Requested": "Ka Nema",
    "Via": "Ta Hanyar",
    "How was your experience?": "Yaya Gogewarka?",
    "Leave a Review": "Bar Sharhi",
    "Overall Ratings": "Jimillar Kimantawa",
    "Visit Profile": "Ziyarci Bayanin Kai",
    "Ratings": "Kimantawa",
    "Reviews": "Sharhi",
    "No reviews available": "Babu sharhin da ake da shi",
    "Select Your Currency": "Zaɓi Kuɗin Ka",
    "Proceed": "Ci Gaba",
    "Commision": "Hukumar",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Unlock Seamless Transactions with InstaPay QR Code Sticker - Buɗe Mu'amaloli Marasa Tsayawa tare da Sticker na Lambar QR ta InstaPay",
    "Effortless and Swift:": "Ba tare da kokari ba kuma da sauri.",
    "Instantly download your unique InstaPay QR code sticker.": "Sauke manhajar tambarin QR code na musamman na InstaPay nan take.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Kunna biyan kudi ba tare da tsangwama ba, ba tare da amfani da tsarin hada-hadar kudi mai rikitarwa irin su na'urorin biyan kudi ba.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Saurin tattara kudaden biya – babu buƙatar shigar da lambobin katin kuɗi ko waya mai gajiya.",
    "Each payment includes customer information for your convenience.": "Kowane biya ya ƙunshi bayanan abokin ciniki don sauƙin ku.",
    "Economically Smart:": "Wayewa a Tattalin Arziki:",
    "Zero activation fees – start immediately without upfront costs.": "Babu kuɗin kunna – fara nan take ba tare da biyan kuɗi na farko ba.",
    "Forget about fixed monthly charges; pay as you transact.": "Manta da cajin wata-wata; biya yayin da kake yin ma'amala.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Kwace kayan aiki; rashin kuɗin na'urar biyan kuɗi na nufin ƙarin tanadi a gare ku.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Ji daɗin ƙananan kuɗin ma'amala a kawai 0.75% a kowane biyan kuɗi ta QR code.",
    "We don't impose minimum commissions; you save more with each transaction.": "Ba mu sanya mafi ƙarancin kwamiti; kuna tanadi da yawa a kowace ma'amala.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ji daɗin biyan kuɗi nan take, yana inganta gudanar da kuɗin ku.",
    "Make Every Transaction Count with InstaPay": "Tabbatar da Kowace Ma'amala tana da Muhimmanci tare da InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Sauke QR",
    "QR Code Status": "Status na QR Code",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Raba lambar QR Code ko hanyar adireshin biyan ku don karɓar kuɗi cikin sauri a cikin Wallet ɗin ku na InstaPay.",
    "QR Code Title": "Take na QR Code",
    "View Transactions": "Duba Ma'amaloli",
    "My QR Codes:": "Lambobin QR na:",
    "Load More": "Ɗaukar Ƙari",
    "Benefits Of Portfolio": "Fa'idodin Portfolio",
    "Benefits:": "Fa'idodi:",
    "Enhanced Visibility:": "Ƙara Ganuwa:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Ɗaga bayananku na jama'a. Portfolio ɗin ku yana aiki a matsayin nunin dindindin, ana samun dama kai tsaye daga Adireshin Biyan ku.",
    "Engage Your Audience:": "Haɗa Kai da Masu Sauraron Ku:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Haɗa kai da baƙi ta hanyar tafiyar ku ta ƙirƙira ko ƙwararru. Bari ayyukanku su yi magana a madadinku, suna samar da zurfin haɗin gwiwa tare da abokan ciniki ko magoya baya da ke yiwuwa.",
    "Integrated Social Proof:": "Tabbatarwa ta Zamantakewa da aka Haɗa:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Nuna tasirin ku na kafofin watsa labarun. Baƙi za su iya ganin yadda kuke isa ga kafofin watsa labarai, suna ƙara inganci da mahallin ga portfolio ɗin ku.",
    "Seamless Transactions:": "Ma'amaloli Marasa Matsala:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Baiwa baƙi damar neman ko fara biyan kuɗi kai tsaye daga shafin ku na jama'a. Portfolio ɗin ku ba kawai yana nuna ayyukanku ba, har ma yana sauƙaƙa hulɗar kuɗi cikin sauƙi.",
    "Personalized Storytelling:": "Labarin Kai na Musamman:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Yi amfani da sashen 'Game da Ni' don raba labarinku, manufa, ko hangen nesa, wanda ke samar da alaƙa ta sirri tare da masu sauraron ku.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "My Portfolio' ba kawai hoton baje koli bane; kayan aiki ne don gina alamar ku, haɗa kai da masu sauraron ku, kuma saukake hulɗar kuɗin ku a InstaPay.\n",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Nuna Ayyukanku, Fadada Isarku. 'My Portfolio' shine murfin dijital dinku a InstaPay. Loda kuma nuna abubuwa daban-daban – daga hotuna masu jan hankali da bidiyo zuwa PDF masu bayani. \nKo kai zane-zane, mai zaman kansa, ko dan kasuwa ne, wannan fasalin yana baka damar gabatar da ayyukanka ko ayyukanka cikin hanyar gani mai jan hankali.",
    "Add/Manage Portfolio": "Ƙara/Sarrafa Portfolio",
    "Add New": "Ƙara Sabo",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksimantar da Samunku a Kafofin Watsa Labarai da Adireshin Biyan Ku na InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Sanya Yana da Sauki Ga Masu Sauraronku Don Tallafa Muku:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Sauƙi haɗa Adireshin Biyan Ku na InstaPay cikin sakonnin kafofin watsa labarun ku.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Sauƙaƙe kwafa da liƙa adireshin biyan kuɗin ku a cikin kowane ɗaya daga cikin sakonninku, labarai, ko a bayyane a bayanan martabarku.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Dacewa ga masu tasiri, masu zane-zane, 'yan kasuwa, ko kowa da kowa tare da al'umma da ke sha'awar nuna goyon bayansu.",
    "Instant Support, Endless Possibilities:": "Tallafi Nan take, Dama Mara iyaka:",
    "Transform the way you monetize your social media content.": "Sauya yadda kuke samun kudi daga abubuwan da kuke wallafawa a kafofin watsa labarai.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Baiwa mabiyanku damar aika muku kuɗi nan take da dannawa ɗaya kai tsaye daga hanyoyin sadarwar zamantakewar da suka fi so.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Dacewa don tallafi a ainihin lokacin yayin gudana kai tsaye, a matsayin tsarin tukwici don abubuwan da kuke halitta, ko don samar da kuɗi don babban aikin ku na gaba.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Ina Zaku Sanya Adireshin Biyan Ku na InstaPay Don Tasirin Mafi Girma:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Bayanan Kafofin Watsa Labarai: Makala shi a tarihin ku ko sashen game da ku don ganuwa akai-akai.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Sakonni & Sanarwa: Haɗa shi cikin sakonninku na mutum, musamman waɗanda ke nuna ayyukanku ko kira na musamman don tallafi.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Labarai & Faifan Bidiyo: Haɗa shi cikin labaran ku na hulɗa ko faifan bidiyo masu jan hankali don samun gudummawar nan take.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Gudana Kai Tsaye: Raba shi yayin zaman ku na Kai Tsaye, yana sa ya zama mai sauƙi ga masu kallo su bayar da gudummawa a ainihin lokaci.",
    "Simple, Fast, and Secure:": "Sauƙi, Azumi, kuma Tsaro:",
    "No complex setup – get started in moments.": "Babu tsarin saiti mai rikitarwa – fara a cikin lokuta.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Ji daɗin tsaro da saurin sarrafa biyan kuɗi na InstaPay da ake dogara da shi.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Karɓar kuɗi daga kowa, ko'ina, ba tare da buƙatar su da asusun InstaPay ba.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Daga Matsayin Wasan Kafofin Watsa Labaranku da Adireshin Biyan Ku na InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Adireshin Biyan Kudi na Virtual)",
    "Payment Address Title": "Take na Adireshin Biya",
    "Currency": "Kudin",
    "Payment Address Description": "Bayanin Adireshin Biya",
    "Update": "Sabunta",
    "Updating": "Ana Sabuntawa",
    "Total Transaction": "Jimlar Ma'amala",
    "Last 30 days": "Ƙarshen kwanaki 30",
    "Last 1 month": "Ƙarshen wata 1",
    "Last 1 year": "Ƙarshen shekara 1",
    "Custom Date": "Kwanan Wata na Musamman",
    "Payment Methods": "Hanyoyin Biya",
    "Payment Types": "Nau'ikan Biya",
    "Requested Amount": "Adadin da Aka Nema",
    "Quoted Amount": "Adadin da Aka Ambata",
    "About Me": "Game da Ni",
    "Followers": "Mabiyansu",
    "InstaPay ID": "ID na InstaPay",
    "Download QR Code": "Sauke Lambar QR",
    "Initiate A Payment": "Fara Biyan Kudi",
    "Report this person": "Rahoto Wannan Mutum",
    "Add now": "Ƙara Yanzu",
    "Recent Reviews": "Bita na Kwanan nan",
    "Reviews As Seller": "Bita A Matsayin Mai Sayarwa",
    "Reviews As Buyer": "Bita A Matsayin Mai Siye",
    "Select Language": "Zabi Yare",
    "Profile Completion": "Kammala Bayanin Martaba",
    "Profile Completed": "An Kammala Bayanin Martaba",
    "Basic Info": "Bayanan Asali",
    "Username": "Sunan Mai Amfani",
    "Gender": "Jinsi",
    "Male": "Namiji",
    "Female": "Mace",
    "-Select-Gender-": "-Zabi-Jinsi-",
    "Date Of Birth": "Ranar Haihuwa",
    "Address": "Adireshi",
    "Edit Profile": "Gyara Bayanin Martaba",
    "Update Profile": "Sabunta Bayanin Martaba",
    "Updating Profile": "Ana Sabunta Bayanin Martaba",
    "Complete Now": "Kammala Yanzu",
    "Password": "Kalmar Sirri",
    "Change Password": "Canza Kalmar Sirri",
    "New Password": "Sabuwar Kalmar Sirri",
    "Re-enter New Password": "Sake Shigar da Sabuwar Kalmar Sirri",
    "Please follow this guide for a strong password": "Don Allah bi wannan jagorar domin samun kalmar sirri mai ƙarfi",
    "Include at least one special character.": "Haɗa aƙalla alamar musamman ɗaya.",
    "Minimum of 8 characters.": "Mafi ƙarancin haruffa 8.",
    "Include at least one number": "Haɗa aƙalla lamba ɗaya",
    "Change it often for enhanced security.": "Canza shi akai-akai domin ƙara tsaro.",
    "Security Questions": "Tambayoyin Tsaro",
    "Security Question": "Tambaya ta Tsaro",
    "Select your question": "Zabi tambayarku",
    "Answer To The Question": "Amsa ga Tambaya",
    "Nominee": "Wanda Aka Zaba",
    "Relationship with Nominee": "Alaƙa da Wanda Aka Zaba",
    "Nominee Contact Number": "Lambar Tuntuɓar Wanda Aka Zaba",
    "Nominee Address": "Adireshin Wanda Aka Zaba",
    "Delete Nominee": "Cire Wanda Aka Zaba",
    "Update Nominee": "Sabunta Wanda Aka Zaba",
    "Details Updated Successfully!": "Bayanai An Sabunta Su Nasara!",
    "Success": "Nasara",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Ka iya zaɓa Nominee na damuwa, idan aka samu damuwa, ta yadda za ku yi amfani da shafukan InstaPay. Kuna sanar da ba za a buƙatar saƙo ƙara ga Nominee, kuma a bayyana cewa daftarwa \nta intanet zai kasance ƙarshe da ya kafa. A cikin damuwa da mutumin da ya mutu mai tsabta ga hali, rikicin a cikin Wallet zai yi nadin ga Nominee da aka daftar. KEMIT KINGDOM zai fara ba da \ntallafin sa kan duk wani damuwan bayi bayan gudanar da sakon Wallet ga Nominee. Nominee zai ba da aikace-aikacen dokokin da suke buƙatar InstaPay, kamar su na bayanin hakkin da suka \nbuƙata da gaskiya.",
    "Add Nominee": "Zaɓa Nominee",
    "Two Factor Authentication": "Biyan kwafin Biyu",
    "SMS Number": "Namban SMS",
    "Updated Successfully!": "Cire Don ci gaba!",
    "Social Network Accounts": "Shafukan Yanar Gizo na Jam'iyyu",
    "Here, you can set up and manage your integration settings.": "A nan, za ka iya kafa da ayyukan kwarewa da kuma kula da tallafin intanet na ku.",
    "Social Network Account": "Shafukan Yanar Gizo",
    "Activate Account": "Kariya Akauntin",
    "Enter the code below in the InstaPay Chatbot.": "Shiga ta koda ta hanyar da lambobin da ke ɗaya a InstaPay Chatbot.",
    "The code will expire in ": "Lambobin zai bincike a",
    "Notifications": "Bayanai",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Kula da yadda za ka sami bayanai. Wannan ayyuka sun nuna ga abubuwan da kake damu da su.",
    "Activity": "Abubuwa",
    "Email": "Imel",
    "Push": "Yiwa Yanar Gizo",
    "SMS": "SMS",
    "Source": "Makirci",
    "Payment Requests": "Maɓayye na Kuɗi",
    "Bulk Payments": "Kudi Masu Kudi",
    "Identity Verification": "Sabbin Binciken Kwarewa",
    "Verify Your Identity": "Kwarewa Ta Lura Ku",
    "Identity Verification Status:": "Hali Na Kwarewa Ta Lura:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Ka bayyana yadda za ka ce ku da sababbin da za ka yi amfani da InstaPay.",
    "Enter Your Message": "Shiga Manufofin Ku",
    "Select Occupation": "Zaɓa Sabon Aiki",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Za a iya kula manufofin da aikin ƙasar ya bayar da shi tare da hoto. Duk manufofin da ake kula zai bayar da harsashin Latin. Idan ba haka ba, za a buƙatar hujjar da aka sa ba.",
    "Accepted Documents": "Manufofin da aka karɓi",
    "A national identity card": "Kati yankin tsarin jama'a",
    "A valid passport": "Kati yankin haɓaka",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Manufofin Tsarin ɗakunan launi (aka karɓa kawai idan aka bayyana ta wajen ƙasar Turai ko na Switzerland)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometric Facial Recognition: Kariya ga fideo tare da hoto na takardar banki",
    "Address Verification:": "Kariya ga ƙwarewar ƙaramin tsarin launi: Kariya ga fideo tare da hoto na takardar banki",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Rufe takardar banki ko bukatar buɗe kan wani fasali mai amfani da manufofin adireshin (sauki kawai da uku na wata a cikin watan guda biyu) don bukatar ayyuka ta adireshin.",
    "Additional Documents:": "Manufofin sauran:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom ya ba da damuwa ya shawo damuwa don su bukatar sa ranar damuwa da manufofin sauran (Kwarewar Ba Da Ayyukan Samar Da Harshe - EDD) ko bayanai masu bukatun masu \namfani don yin kwarewa masu ɗaukaka, a karkashin ayyukan shirin da aka cire tsarin tsarin, a hanyoyin ayyukan hukuncin shi.",
    "Start Verification": "Farawa Kwarewa",
    "Sessions": "Rukuni",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Wannan shi ne jerin kayayyakin da suka sauke da ayyukan ku. Cire duk wanda ba ku sanar da shi.",
    "Your Withdrawal Options": "Zaɓuɓɓukan Janyewar Ku",
    "Choose your preferred method for withdrawing payments.": "Zaɓi hanyar da kuka fi so don janye kuɗi.",
    "No withdrawal options currently available in your country.": "Babu zaɓuɓɓukan janye kudi a halin yanzu a ƙasarku.",
    "Download Your Data": "Sauke Bayananku",
    "Request a Copy of Your Data": "Nemi Kwafin Bayanan Ku",
    "Submit a request to download a copy of your data": "Gabatar da buƙata don saukar da kwafin bayananku",
    "Which Data Should Be Included in the Download?": "Wane Bayani Ya Kamata a Haɗa a Saukarwa?",
    "Select all applicable options.": "Zaɓi duk zaɓuɓɓukan da suka dace.",
    "Personal Information": "Bayanan Kai",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Ana amfani da wannan bayanin don tabbatar da asusunku, keɓance ƙwarewar ku, kuma a tuntuɓe ku kamar yadda ake buƙata.",
    "Name and Date Of Birth": "Sunan ku da kwanan watan haihuwa",
    "Phone number": "Lambar waya",
    "Financial Information": "Bayanan Kuɗi",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Muna amfani da wannan bayanin don saukaka biyan kuɗi da aika ko karɓar kuɗi cikin dannawa kaɗan.",
    "Bank accounts": "Asusun banki",
    "Debit or credit cards": "Katunan debiti ko bashi",
    "Credit Information": "Bayanan Bashin",
    "Includes your credit application information": "Ya ƙunshi bayanan aikace-aikacen bashin ku",
    "Other Information (not included in file)": "Sauran Bayanai (ba a haɗa a fayil ba)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Ya ƙunshi bayanan na'ura, bayanan amfani na fasaha, bayanan wuri na geolocation, zaɓin talla, tarihin yarda, da bayanan da ake amfani da su don wasu ayyuka kamar bashi, tabbatar da shaidar, \nsadarwa tare da PayPal, da masu sarrafa na uku.",
    "Choose file type:": "Zaɓi nau'in fayil:",
    "Select file type": "Zaɓi nau'in fayil",
    "PDF file can be easily opened on any computer": "Ana iya buɗe fayil din PDF cikin sauƙi a kan kowace kwamfuta",
    "Submit Request": "Aika Buƙata",
    "Delete Your Account": "Nemi a goge asusunku",
    "Ask us to delete your data and close this account": "Ku tambaye mu mu goge bayananku kuma mu rufe wannan asusun",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Muna amfani da bayananku don samar da ayyuka, don haka idan muka goge bayananku, dole ne mu kuma rufe asusunku.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Idan an iyakance asusunku, je zuwa Cibiyar Warware Matsaloli, zaɓi 'Je zuwa Iyakokin Asusun', sai ka danna 'Warware'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Tabbatar an biya duk wani bashin da ake bin ku. Ba za mu iya rufe asusunku ba har sai an yi haka.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Idan kana da ragowar bashi, danna mahadar Warware da ke kusa da ragowar kuɗin ka.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Da zarar an share asusunku, kuma idan kun cancanta, za mu rufe asusunku kuma mu goge bayanan ku. Muna bukatar mu ci gaba da riƙe wasu daga cikin bayananku saboda dalilai na doka da ka'ida.\nDon ƙarin koyo, duba",
    "privacy statement": "sanarwar sirri",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Za mu aiko muku da imel don sanar da ku matsayin, kuma idan akwai wasu matakai da ya kamata ku dauka.",
    "Have more questions about data deletion?": "Kuna da ƙarin tambayoyi game da share bayanai?",
    "Contact us": "Tuntuɓi mu",
    "I understand that account deletion is permanent and can't be reversed.": "Na fahimci cewa share asusu abu ne na dindindin kuma ba za a iya mayar da shi ba.",
    "Are you sure you want to close your account?": "Kuna tabbata kuna son rufe asusunku?",
    "Yes, Continue": "E, Ci gaba",
    "Logging In": "Shiga",
    "Complete your profile to streamline your transaction process.": "Cika bayanan ku don sauƙaƙe tsarin cinikayya.",
    "Skip": "Tsallake",
    "Complete Your Profile": "Cika Bayananku",
    "API Key": "Mabuɗin API",
    "Get API Key": "Samun Mabuɗin API",
    "Generating New API Key": "Samar da Sabon Mabuɗin API",
    "Generate API Key": "Samar da Mabuɗin API",
    "Regenerate API Key": "Sake Samar da Mabuɗin API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Don ganin damuwar da ayyukan daya daga cikin abubuwan da aka samar da ayyuka a cikin InstaPay, yi aikin da wani saukin kyauta domin samar da API key na ku. A nan, ajiye ayyukan a cikin \n[your registration URL] kuma shiga cikin Burtalin ƙwarewa. Kara godewa wurin 'Kalubale na API', yi wani sabon kyauta, kuma duba cewa ya kasance mai inganci.",
    "Delete API Key": "Share tukuna na API",
    "Webhook URL": "URL na Webhook",
    "KYB Verification": "Kadai na KYB",
    "Additional Files": "Manufofin sauran",
    "Business Verification": "Kadai na tasiri na kasa",
    "--Select-Your-Company-Type--": "--Zaɓa-jin-talatin-Labari-- (Zaɓa Tumbin Aiki Na Kuɗin Kuɗi)",
    "Documents Required:": "Manufofin Da Ake Buɗe",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Manufofin na rasuwar shirye-shirye na Kuɗin Kuɗi (Manufofin Dattijan Kayan Aiki na Kuɗin Kuɗi)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Wannan manufofi ne ya dace da 'takardar ɗan' na kwampanya da aka buɗe daga wajen kasa na aiki. Ya nuna hali na ƙwarewa na aiki (kuma yayi amfani da shi ko ya ƙarɓi). Za a samu shi daga tsohon \nkasa na aiki na aiki.",
    "The document should be current, with an issuance date not exceeding 3 months.": "Manufofin ya bukatar samun shine a yau, da tarihinsa ba za a yi ƙarshe da ƙarancin watan guda biyu ba.",
    "The document must include the following details:": "Takardar dole ta ƙunshi waɗannan bayanan:",
    "Legal name of the company": "Sunan launin kuɗi na kwampanya",
    "Full registered address": "Cikakken adireshin rajista",
    "Company registration number": "Namban launin kuɗin kwampanya",
    "Date of incorporation": "Ranar ɗaukar ciki",
    "Description of the company's activities/purpose": "Bayani game da aiki/karatu na kwampanya",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Bayani na tsohon launin kuɗi: sunan launin kuɗi na gudun karɓi, ranar da aka ja, wani adireshin ɗaukar ciki (ayi nasarar karfafa: watan kasar ta gudun karɓi), da kuma ilimi",
    "If applicable, the document should also include:": "Idan ya dace, takardar ya kamata ta ƙunshi:",
    "Brand or trading names": "Sunan ko launin kuɗin buɗe",
    "Value Added Tax (VAT) number": "Lambar Harajin Ƙara Darajar (VAT)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Jerin Kuɗi Mai Sauka na Ma'ajin Kuɗi (UBOs) - wannan shi ne ayyukan shirya",
    "Proof of Legal Representative's Authority": "Shaida ta Wakilcin Shari'a",
    "This document validates an individual's authority to represent the company": "Wannan manufofi ya ba da damuwa kan damuwar damuwa na wani mutum don taimakawa kwampanya.",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Kamar yadda babu damuwa, tsohon launin kuɗi ya samu suna a cikin manufofi na kwampanya. A bayan haka, idan hakan bai hana damuwa, za a bukaci samun wani manufofi kamar misalin Kuɗin Aiki ko \nKarɓar Kuɗi don taimakawa.",
    "Power of Attorney/Delegation of Powers:": "Ikon Lauya/Wakilcin Iko:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Wanda ke ba da iko dole ne ya kasance da halattacciyar damar yin haka. Wannan mutum ya kamata a jera shi a matsayin wakilin shari'a a cikin fitar da rajistar kamfanin.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Tsayar da Mallaka da Takardun Masu Amfani da Amfani na Ƙarshe (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Akwai buƙatar bayar da shaida na jera kamfanin a kan musayar hannun jari da ya dace. Wannan na iya zama shafi daga gidan yanar gizon musayar hannun jari da ke nuna bayanan kamfanin.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Idan kasa da 75% na hannun jarin kamfanin suna hannun jama'a, dukkan masu hannun jari da suka mallaki ko suke sarrafa sama da 25% na hannun jarin za su kasance a ƙarƙashin buƙatun tabbatar \nda Mai Amfani da Amfani na Ƙarshe (UBO).",
    "Collection of Identification Documents": "Tattarawar Takardun Shaida",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Takardar shaidar dole ne gwamnati ta bayar, dauke da hoton mutum. Dole ne duk takardun shaida su kasance a rubuce cikin haruffan Latin. Idan ba haka ba, ana buƙatar fassara da aka tabbatar.",
    "Accepted documents:": "Takardu da aka karɓa:",
    "Valid National Identity Card": "Katin Shaidar Ƙasa Mai Inganci",
    "Valid Passport": "Fasfo Mai Inganci",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Izinin Zama na Dindindin - Waɗannan za a karɓa ne kawai idan an fitar da su ta ƙasar Turai.",
    "Only legal representatives are subject to the identification document collection process.": "Kawai wakilan shari'a ne ke ƙarƙashin tsarin tattarawar takardun shaida.",
    "Additional Requirement:": "Ƙarin Bukata:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "A yanayin da ake da Wakilci na Iko ko Delega na Iko, ɓangarorin biyu (mutumin da ke bada iko da kuma wanda ke karɓar sa) dole ne su samar da kwafin katin shaidar su.",
    "Additional Documents": "Ƙarin Takardu",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Lissafin Amfanin Gida (kamar na gas, wutar lantarki, tarho ko lissafin wayar hannu); ko wata takarda da hukumar gwamnati ta fitar wacce ke nuna adireshin Mai amfani da sunansa \n(misali, Takardar Shaidar Zama); ko\"",
    "Bank Statement; or": "Takardar Bayanin Banki; ko",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "wata takarda da hukumar gwamnati ta fitar wacce ke nuna adireshin Mai amfani da suna (misali, Takardar Shaidar Zama).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom tana riƙe da haƙƙin neman duk wani ƙarin takardu ko ƙarin bayanai da suka wajaba don gudanar da cikakken bincike, bisa ga nauyin doka da ke kanta.",
    "Note:": "Lura:",
    "Partners": "Abokan hulɗa",
    "Add New Partner": "Ƙara Sabon Abokin Hulɗa",
    "Designation": "Mukami",
    "Partner Name": "Sunan Abokin Hulɗa",
    "Partner Email": "Imel ɗin Abokin Hulɗa",
    "Partner Designation": "Mukamin Abokin Hulɗa",
    "Add Now": "Ƙara Yanzu",
    "Adding": "Ana Ƙara",
    "Delete Partner": "Cire Abokin Hulɗa",
    "Delete": "Cire",
    "Deleting": "Ana Cirewa",
    "Are you sure, you want to remove sxz as partner?": "Kuna tabbata, kuna so ku cire sxz a matsayin abokin hulɗa?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Fitar da Rajista na Hukumar (Takardar Rajista/Halittar Hukumar)",
    "This document should be either:": "Wannan takarda na iya kasancewa:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Shaida na halittar hukumar, tare da bayanin hukumar, ranar halitta, da manufa.",
    "A local registry extract:": "Fitar da rajista ta gida:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Ake buƙata bisa ƙa'idodin ƙasar, tana aiki a matsayin 'katin shaida' na yanzu don hukumar, tana nuna matsayinta (mai aiki ko an rufe).",
    "If a registry extract exists, it must not be older than 3 months.": "Idan akwai fitar da rajista, dole ne kada ya wuce watanni 3 da haihuwa.",
    "Entity name": "Sunan hukumar",
    "Registration number (if applicable, based on the country's requirements)": "Lambar rajista (idan ya dace, bisa ga buƙatun ƙasar)",
    "Date of creation": "Ranar halitta",
    "Description of the entity's activities or purpose": "Bayanin ayyukan hukumar ko manufarta",
    "Trading names": "Sunayen kasuwanci",
    "Articles of Association": "Kundin Tsarin Mulki",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Kundin Tsarin Mulki yana aiki a matsayin takardar tsarin mulki na hukumar, yana tsara dokokin aikinta.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Babu iyakar lokaci akan wannan takarda, ma'ana ana buƙatar mafi sabunta sigar. Gabaɗaya, irin waɗannan hukumomi suna buƙatar taro na shekara-shekara aƙalla guda ɗaya wanda zai iya haifar da \nsabunta sigar Kundin Tsarin Mulki.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Misalan canje-canjen da za su iya faruwa sun haɗa da sabuntawa ga sunan hukumar, adireshi, manufa, kwamitin gudanarwa, rawa, alhakai, da sauransu.",
    "The document should contain information about:": "Takardar ya kamata ta ƙunshi bayani game da:",
    "The entity itself, including its legal name, registered address, and purpose": "Hukumar kanta, ciki har da sunanta na doka, adireshin rajista, da manufa",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Dokokin aiki na cikin gida, kamar tsarin naɗa mambobin kwamitin gudanarwa, rawa da iko, da sauran bayanai. Wannan jerin ba shi da iyaka.",
    "How the entity is funded": "Yadda ake samar da kuɗi ga hukumar",
    "Appointment/Removal/Changes on Board of Management Members:": "Naɗa/Cire/Canje-canje a Kwamitin Gudanarwa:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Waɗannan sabuntawa galibi suna faruwa bisa buƙatar hukumar ko kamar yadda Kundin Tsarin Mulki ya tanada. Ana rubuta canje-canje ta hanyar mintunan taron kwamitin gudanarwa, waɗanda \nake rajista da tabbatar da su don nuna jerin sabbin mambobi, kamar shugaban, mataimakin shugaban, mai kula da kuɗi, da babban sakatare.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Za a buƙaci mafi sabon mintunan taron kwamitin gudanarwa, da jerin mambobin na yanzu, tare da Kundin Tsarin Mulki, idan akwai.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Lura: Idan jerin mambobin kwamitin gudanarwa na yanzu ba a haɗa shi a cikin mafi sabon Kundin Tsarin Mulki ba kuma babu mintunan taron kwamiti da ake da su, dole ne a bayar da wannan jerin. \nYa kamata a yi masa kwanan wata kuma a sa hannu ta wakilin doka da aka ɗauka kwanan nan, wanda naɗin sa za a iya tabbatar da shi ta hanyar takardu masu alaƙa.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Wannan takarda tana nuna ikon mutum don wakiltar hukumar. Wannan takardar na iya zama:",
    "The latest Articles of Association": "Mafi sabon Kundin Tsarin Mulki",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Jerin mambobin kwamitin gudanarwa na yanzu (wanda kuma za a iya haɗa shi a cikin mintunan taron kwamitin gudanarwa)",
    "The document should specify:": "Takardar ya kamata ta bayyana:",
    "Full name of the individual(s)": "Cikakken sunan mutum/mutane",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Aikin mutum/mutane. Za a bincika wannan bayanin tare da Kundin Tsarin Mulki don tabbatar da cewa aikin mutum ya ba shi damar ɗaure hukumar. Wannan yana da muhimmanci musamman idan mai \nnema ba shi ne shugaban ba, kuma sauran ayyuka suna da iyakantaccen iko na wakilci kamar yadda Kundin Tsarin Mulki ya bayyana.",
    "Additional information about the legal representative that will be collected includes:": "Ƙarin bayani game da wakilin shari'a da za a tattara ya haɗa da:\n",
    "Date and place of birth": "Ranar haihuwa da wurin haihuwa",
    "Residential address (at least the country of residence should be stated)": "Adireshin zama (aƙalla ƙasar zama ya kamata a ambata)",
    "Nationality": "Ƙasa",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Tsayar da Mallaka da Takardun Masu Amfani da Amfani na Ƙarshe (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Gabaɗaya, irin waɗannan hukumomi, saboda tsarinsu da manufarsu, ba su da masu amfani da amfani. Duk da haka, za a gane mambobin kwamitin kuma a ɗauke su a matsayin haka don dalilai \nna tantancewa, tarin ID da tabbatarwa, da sauransu.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Ƙananan yawan ƙungiyoyi zasu iya samun masu amfani da amfani, musamman waɗanda ke samar da riba. A irin waɗannan yanayi, za a ambaci wannan bayanin a Kundin Tsarin Mulki. \nWaɗannan mutane za a gane su da sauki kuma a ɗauke su a haka, tare da mambobin kwamitin gudanarwa.\"",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Ƙarin Buƙata: A lokutan da abin ya shafi Ikon Lauya ko Wakilcin Iko, dukkan bangarorin biyu (wanda ke ba da iko da wanda ke karɓa) dole ne su bayar da kwafin takardun shaidarsu.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Fitar da Rajista na Mai Ciniki Kai (Takardar Rajista/Halittar Hukumar)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Wannan takarda tana aiki a matsayin rajistar gida ga masu cinikin kai, wanda ake buƙata a ƙasashen da irin wannan rajista ta zama dole. Tana tabbatar da cewa mutum yana aikin sana'a a matsayin \nmai ciniki kai. Sunan wannan takarda ya bambanta daga ƙasa zuwa ƙasa.",
    "Where applicable, the document should not be older than 3 months.": "Inda ya dace, takardar ba za ta wuce watanni 3 da haihuwa ba.",
    "The document must contain:": "Takardar dole ta ƙunshi:",
    "Individual's name": "Sunan mutum",
    "Residential address": "Adireshin zama",
    "Registration number": "Lambar rajista",
    "The sole trader is the only individual subject to the identification document collection process.": "Mai cinikayya shi kaɗai ne mutumin da ake buƙata a tsarin tarin takardun shaida.",
    "No Transactions": "Babu Ma'amaloli",
    "No Pending Items": "Babu Abubuwan da ke Jiran Aiki",
    "No Beneficiaries": "Babu Masu Amfana",
    "Send Money To": "Aika Kuɗi Zuwa",
    "Confirmation": "Tabbatarwa",
    "You are setting": "Kuna saita",
    "as default.": "a matsayin tsoho.",
    "Previously": "A baya",
    "was default QR.": "shine QR na tsoho.",
    "Category": "Rukuni",
    "-Select-From-The-List-": "-Zaɓi-Daga-Jerin-",
    "Add a note": "Ƙara bayani",
    "Categories Added Successfully!": "An Ƙara Rukunoni da Nasara!",
    "Subscription starting date": "Ranar fara biyan kuɗi",
    "Until": "Har zuwa",
    "Next Transaction": "Ma'amala ta Gaba",
    "Transaction Method": "Hanyar Ma'amala",
    "Accept the quotation": "Karɓi ƙididdigar",
    "Choose Currency": "Zaɓi Kuɗi",
    "Accept": "Karɓa",
    "Bargain the quotation": "Sasantawa akan ƙididdigar",
    "Enter Counter Amount:": "Shigar da Adadin da Za a Sake Yi:",
    "Bargain": "Sasantawa",
    "Decline the quotation": "Soke ƙididdigar",
    "Are you sure, you want to decline this quotation?": "Kuna tabbata, kuna son ƙin wannan ƙididdigar?",
    "Decline": "Ƙi",
    "Personal Account": "Asusun Kai",
    "Top-up Your Wallet": "Sanya Wallet ɗinku",
    "Download Statement": "Zazzage Bayanin",
    "Block the Wallet": "Toshe Wallet",
    "Wallet Management": "Gudanar da Wallet",
    "Wallet Status": "Matsayin Wallet",
    "Breakdown": "Rushewa",
    "Credit": "Kiredit",
    "Debit": "Zare kudi",
    "All": "Duka",
    "Sent": "An aika",
    "Received": "An karɓa",
    "Requested": "An nema",
    "Quoted": "An nakalto",
    "Date & Time": "Kwanan Wata & Lokaci",
    "Type": "Nau'in",
    "Transaction ID": "ID na kasuwanci",
    "Payment Type": "Nau'in Biyan Kuɗi",
    "Recipient": "Mai karɓa",
    "Amount": "Adadin",
    "Status": "Matsayi",
    "Search Transactions": "Bincika Ma'amaloli",
    "Subscriptions & Scheduled": "Biyan kuɗi & Tsara",
    "Sent Payments": "An aika Biyan kuɗi",
    "Received Payments": "Biya Da Aka Samu",
    "Date": "Kwanan wata",
    "Cycles/Time": "Zagaye/Lokaci",
    "Payment As": "Biya As",
    "Name": "Suna",
    "Cancel": "Soke",
    "Default": "Tsohuwar",
    "Select the option that matches your needs from the list below:": "Zaɓi zaɓin da ya dace da bukatunku daga lissafin da ke ƙasa:",
    "Trouble Requesting Money?": "Matsala Neman Kudi?",
    "Initiate A Payment": "Fara Biyan Kuɗi",
    "MAIN MENU": "BABBAN MENU",
    "OTHERS": "WASU",
    "Jan": "Jan",
    "Feb": "Fab",
    "Mar": "Mar",
    "Apr": "Afr",
    "May": "May",
    "Jun": "Yuni",
    "Jul": "Yuli",
    "Aug": "Agu",
    "Sep": "Sat",
    "Oct": "Okt",
    "Nov": "Nuw",
    "Dec": "Dis",
    "Today": "Yau",
    "Spent In": "An sa",
    "Revised Amount": "Kudin aiki",
    "You spent more comparing to last month": "Ku yi bada magana a karkashin wata",
    "Last": "Na",
    "days": "Kwanan",
    "Year": "Shekara",
    "Instant Mobile Top-up Across 150+ Countries": "Cika Layin Waya Nan take A Kasashe Sama da 150",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Ka faranta wa masoyanka rai ko ka cika wayarka, duk inda kake! Sabis na Cika Layin Waya na InstaPay yana baka damar cika layukan waya nan take a kasashe sama da 150. Dacewa don kyaututtuka ko tabbatar da kai kullum a haɗe, an tsara sabis ɗinmu don sauƙi, sauri, da tsaro.",
    "How It Works - Step-by-Step Guide:": "Yadda Yake Aiki - Jagora Mataki-da-Mataki:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Zaɓi 'Cika Layi': Samu damar wannan fasalin kai tsaye a cikin app ɗin InstaPay akan dandamali kamar WhatsApp, Twitter, Telegram, ko Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Zaɓi Kuɗi da Adadin: Zaɓi daga kuɗaɗe da yawa (USD, GBP, EUR, da sauransu) kuma ka nuna adadin da kake son cikawa.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Shigar da Lambar Waya: Rubuta lambar da kake son cikawa—ta naka ko ta wani da kake so. Tsarinmu yana tabbatar da tsari mai aminci da sauri.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Tabbatar da Biya: Duba bayanan cika layi sau biyu, tabbatar, kuma ka biya ta amfani da hanyar da kake so. Ana sarrafa ma'amalar nan take kuma cikin aminci.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Karɓi Tabbacin Nan take: Duk kai da wanda aka cika wa (idan ya dace) za su sami sanarwar tabbaci da zarar an yi nasarar cika layin.",
    "Benefits:": "Fa'idodi:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Iza ga Duniya: Aika layin waya cikin sauki zuwa kasashe sama da 150.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Tasirin Nan take: Gani fa'idodin nan take—cikakke don kyaututtuka na ƙarshe ko sadarwa mai gaggawa.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Cikakken Tsaro: Jin daɗin kwanciyar hankali tare da tsarin biyanmu mai aminci.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Zaɓuɓɓukan Biyan Kuɗi Daban-daban: Zaɓi daga katunan kuɗi, PayPal, ko walat ɗin InstaPay don biyan kuɗi.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Shirye don yada farin ciki ko ci gaba da haɗi? Cika layi yanzu kuma ka faranta ran wani—ko naka!",
    "Pick a language": "Zaɓi harshe",
    "French": "Faransanci",
    "Spanish": "Sifaniyanci",
    "Portuguese": "Harshen Portuguese",
    "German": "Jamusanci",
    "Ukrainian": "Yaren mutanen Ukraine",
    "Italian": "Italiyanci",
    "Russian": "Rashanci",
    "Arabic": "Larabci",
    "Polish": "Harshen Polish",
    "Dutch (Nederlands)": "Holanci",
    "Yoruba": "Yoruba",
    "Indonesian": "Harshen Indonesian",
    "Turkish": "Turanci",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Harshen Hindi",
    "Urdu": "Harshen Urdu",
    "Chinese": "Harshen Sinanci",
    "Select Your Preferred Language": "Zaɓi Harshen da Kake So",
    "Please select the language you would \nlike to use for the eKYC process.": "Da fatan zaɓi yaren da kake son amfani da shi don aikin eKYC.",
    "Select Your Nationality": "Zaɓi Ƙasarka",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Zaɓi ƙasarka don ganin takardun shaida da ake tallafawa don ƙasarka.",
    "Grant permission to use the camera.": "Ba da izinin amfani da kyamara.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Matsa ko danna \"Bada izini\" lokacin da aka tambaye ka don ba da izinin samun damar kyamara don ɗaukar takaddar.",
    "Upload Your Documents from \nGallery/Photos": "Loda Takardunku daga Hotuna/Galeri",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Idan kuna fuskantar matsaloli tare da kyamararku ko idan tsarin ku baya da shi, kuna iya loda hotunan takaddar daga cikin ajiya ta cikin gida.",
    "Upload Your ID.": "Loda ID ɗinku.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Tabbatar da cewa ID ɗinku a sarari ba tare da ɗimbin ɗauri ba don tabbatar da ingantaccen tabbaci.",
    "Valid Proof of Address": "Ingantaccen Shaida na Adireshi",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Tabbatar da cewa shaidar adireshin ku na yanzu kuma kwanan wata na tsarewa ya kasance cikin watanni uku daga ranar farawar tabbatarwar ku ta eKYC.\n\n2. Tabbatar da cewa shaidar adireshin ku a sarari ba tare da ɗimbin ɗauri ba don tabbatar da ingantaccen tabbaci.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Sanarwa Muhimma\n\nDa fatan za a karanta duk matakan wannan jagorar da kyau kafin fara tantancewar eKYC ɗinku.\n\nGuji kurakuran gama gari:\n\n* Tabbatar da cewa duk takardunku sun kasance a bayyane, ana iya karantawa kuma na zamani.\n* Duba cewa bayanan da kuka bayar sun yi daidai da bayanan da ke cikin takardunku na hukuma.\n* Bi umarnin daidai don guje wa jinkiri ko ƙin karɓa.\n\nLura: \nIdan tantancewar eKYC ɗinku ta gaza kuma kuna buƙatar sake mika shi, za a cajeku ƙarin kuɗi don sake mika shi.\n\nTa bin waɗannan jagororin, zaku iya tabbatar da cewa tantancewar eKYC ɗinku ta kasance mai sauƙi da nasara.\n\nDon duk wani tambaya ko taimako, da fatan za a tuntuɓi ƙungiyar tallafinmu a support@insta-pay.ch",
    "Personal Transactions": "Mu'amaloli na Kashin Kai",
    "Business Transactions": "Mu'amaloli na Kasuwanci",
    "Shopping & Purchases": "Siyayya da Saye",
    "Bills & Utilities": "Kudin Lantarki & Kudin Ruwa",
    "Entertainment & Leisure": "Nishaɗi da Hutawa",
    "Investments & Savings": "Zuba Jari da Ajiya",
    "Health & Wellness": "Lafiya & Jin Dadi",
    "Transportation": "Sufuri",
    "Education": "Ilimi",
    "Miscellaneous": "Na daban",
    "Family Support": "Tallafin iyali",
    "WALLET": "WALLET",
    "Add a currency": "Ƙara kudin",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Lokacin da ka ƙara kudin, duk wani biyan da ka karɓa a cikin kudin zai kasance a cikin ma'aunin InstaPay naka. Ana amfani da kudin ku na farko don aika ko neman biyan kuɗi sai dai in an bayyana akasin haka.",

    "balance amount ": "adadin ma'auni",
    "referral ": "nemi",
    "crypto wallet balance": "ma'aunin aljihun crypto",
    "money in": "kudi a ciki",
    "money out": "kudi a waje",
    "analytics": "bincike",
    "see more": "duba ƙari",
    "manage my Wallets and see the transaction details": "sarrafa Wallets na kuma duba bayanan ma'amaloli na",
    "set as default Wallet": "saita a matsayin Wallet na tsoho",
    "add currency": "ƙara kudin",
    "convert funds ": "juyar da kuɗi",
    "withdraw balance": "janye ma'auni",
    "chart names": "sunan ginshiƙi",
    "withdraw balance from PKR wallet": "fitar da ma'auni daga walat PKR",
    "select a payout channel": "zaɓi hanyar biyan kuɗi",
    "you have entered an amount below the minimum payment range": "kun shigar da adadi da ke ƙasa da ƙaramar adadin biyan kuɗi",
    "change": "canjawa",
    "edit": "gyara",
    "moving to next step in ...": "tafi zuwa mataki na gaba a ...",
    "you are withdrawing ...": "kuna cire ...",
    "where is my payment": "ina biyan kuɗi na",
    "download": "zazzage",
    "IBAN / SWIFT Code": "IBAN ko lambar SWIFT/BIC",
    "swift code": "lambar SWIFT",
    "account number": "lambar asusu",
    "branch street": "titin reshe",
    "city": "birni",
    "province": "lardi",
    "postal code": "lambar gidan waya",
    "all payout channels": "duk tashoshin biyan kuɗi",
    "bussiness beneficiary": "maibenefin kasuwanci",
    "no quotation": "ba faɗar farashi",
    "no pending items": "babu abubuwan da ake jira",
    "QR status": "Matsayin QR",
    "Portfolio": "Jaka",
    "edit whole portfolio page": "gyara duk shafin jaka",
    "personal account": "asusun kai",
    "Chart names": "Sunayen ginshiƙi",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "sauran",
    "not connected": "ba a haɗa ba",
    "code": "lambar sirri",
    "ratings": "kimantawa",
    "current password": "kalmar wucewa na yanzu",
    "include at least one uppercase and lowercase": "hade akalla babban harafi da ƙaramin harafi",
    "my social network accounts": "asabobina na hanyar sadarwa",
    "SMS ": "SMS",
    "view identity verfication guide": "duba jagorar tabbatar da ainihi",
    "boimetric face recognition": "gane fuska na biometric",
    "please select a payer (withdrawl options)": "don Allah zaɓi mai biyan kuɗi (zaɓuɓɓukan cirewa)",
    "IBAN number ": "lambar IBAN",
    "account holder name": "sunan mai asusun",
    "download data": "zazzage bayanai",
    "bank transfer": "canja wurin banki",
    "mobile wallet": "aljihun tafi da gidanka",
    "all bank names": "duk sunayen banki",
    "pay via card": "biya ta katin",
    "you have entered an amount that is below the payer range": "ka shigar da adadin da ke ƙasa da kewayon mai biya",
    "trouble sending money": "matsala a aika kudi",
    "please follow these example": "don Allah ku bi waɗannan misalan",
    "wallet ID ": "ID ɗin Wallet",
    "username": "sunanka",
    "email": "imel",
    "phone number": "lambar waya",
    "no user found": "ba a sami mai amfani ba",
    "search": "bincika",
    "scan/upload QR code of the recipient": "duba/ɗora lambar QR na mai karɓa",
    "invite them": "gayyace su",
    "trouble quoting money": "matsala wajen fadin farashin kudi",
    "select a country": "zaɓi ƙasa",
    "airtime details": "cikakkun bayanai na airtime",
    "enter the phone number": "shigar da lambar wayar",
    "service type": "nau'in sabis",
    "select a service": "zaɓi sabis",
    "service list": "jerin sabis",
    "amount entered is less than the ....": "adadin da aka shigar ya ƙasa da ....",
    "total": "adadin",
    "total credit the receiver gets": "jimlar kuɗi da mai karɓa zai samu",
    "take ": "dauki",
    "OTP has been verified , You'll be redirected......": "An tabbatar da OTP, za a tura ku......",
    "Link Your Instagram Account to InstaPay": "Hada asusun Instagram dinka da InstaPay",
    "Step 01 – From Instagram App": "Mataki na 01 – Daga Manhajar Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Bude manhajar Instagram, nema shafin bayanin \"InstaPay\", zaɓi \"Sako\" don buɗe akwatin hira, rubuta/ce \"Sannu\" kuma danna tura don fara tattaunawa da chatbot na InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Mataki na 02 – Daga Shafin Bayanai na Instagram na InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot na InstaPay zai nuna maɓallan aiki biyu: \"Yi Rijista\" da \"Haɗa\". Zaɓi \"Haɗa\", kuma chatbot zai nemi ka shigar da sunan mai amfani na InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Mataki na 03 – Daga Shafin Bayanai na Instagram na InstaPa",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot na InstaPay zai nemi lambar tabbatarwa ta InstaPay. Shigar da lambar da ka samu daga cikin saitunan InstaPay a ƙarƙashin \"Asusun Na Na Social Network\" kuma danna tura.",
    "Step 04 – From InstaPay Instagram Profile Page": "Mataki na 04 – Daga Shafin Bayanai na Instagram na InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Za ka karɓi saƙon taya murna da ke tabbatar da cewa an haɗa asusun Instagram ɗinka yanzu tare da InstaPay. Danna \"Babban Menu\" don fara amfani da sabis ɗin InstaPay.",
    "Additional Tips": "Ƙarin Nasihu",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Kare Asusunka: Tabbatar cewa asusun Instagram ɗinka yana da aminci ta kunna tabbatarwa ta hanyoyi biyu (2FA) don kare sabis ɗin InstaPay da aka haɗa.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Bincika Ƙari: Da zarar an haɗa, bincika kewayon sabis da InstaPay ke bayarwa, daga ma'amaloli masu sauƙi zuwa cire kuɗi nan take.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Ci gaba da Sabunta: Kula da sabuntawa daga gare mu, yayin da muke shirin tallafawa haɗin wasu asusun na social network don sanya kwarewarka ta kasance haɗe da juna sosai.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Taimako & Tallafi: Idan ka fuskanci matsaloli yayin aiwatar da haɗin, tuntuɓi tawagar tallafi don taimako.",
    "Future Enhancements": "Sabbin Ingantawa",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Nan gaba kadan, InstaPay zai ba ka damar haɗa ƙarin asusun na social network, yana ba ka ƙarin sassauci da sauƙi. Ci gaba da kula da sabbin abubuwan ban sha'awa!",
    "Personal Transactions": "Mu'amaloli na Kashin Kai",
    "Business Transactions": "Mu'amaloli na Kasuwanci",
    "Shopping & Purchases": "Siyayya da Saye",
    "Bills & Utilities": "Kudin Lantarki & Kudin Ruwa",
    "Entertainment & Leisure": "Nishaɗi da Hutawa",
    "Investments & Savings": "Zuba Jari da Ajiya",
    "Health & Wellness": "Lafiya & Jin Dadi",
    "Transportation": "Sufuri",
    "Education": "Ilimi",
    "Miscellaneous": "Na daban",
    "Family Support": "Tallafin iyali",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Fara Tafiyar InstaPay Dinka A Yau!",
    "Enter your phone number": "Shigar da lambar wayarka",
    "Login with email": "Shiga tare da imel",
    "Don't have an account?": "Babu asusu?",
    "Sign Up": "Yi rajista",
    "Continue": "Ci gaba",
    "We recommend you download and install the Google Authenticator app": "Muna ba da shawarar ka sauke kuma ka girka app ɗin Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Tabbatarwa ta hanyoyi biyu (2FA) ita ce hanya mafi kyau don kare kanka akan layi.",
    "Strong security with Google Authenticator": "Kariya mai ƙarfi tare da Google Authenticator",
    "Enter your password": "Shigar da kalmar sirrinka",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Muna ba da shawarar ka sauke kuma ka girka app ɗin Google Authenticator don tabbatar da cewa ka karɓi lambobin tabbatarwarka nan take, guje wa kowanne jinkiri ko matsaloli tare da SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Lambar Tantancewa ta Hanyoyi Biyu",
    "Problem with the token?": "Matsala da token?",
    "Verify using SMS": "Yi amfani da SMS don tabbatarwa",
    "Verify": "Tabbatar",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Tabbatarwa ta SMS",
    "Enter the SMS code sent to +41********4053": "Shigar da lambar SMS da aka aiko zuwa +41********4053",
    "Verify using Authenticator App": "Yi amfani da app ɗin Authenticator don tabbatarwa",
    "Resend code in 120 seconds": "Sake aika lambar a cikin daƙiƙa 120",
    "Verify ": "Tabbatar",
    "SMS - Web Mobile": null,
    "My own account": "Asusuna na kaina",
    "Friends & Family": "Abokai & Iyali",
    "Aunt": "Gwaggo",
    "Brother-in-law": "Dan uwa",
    "Cousin": "Danja",
    "Daughter": "‘Ya",
    "Father": "Uba",
    "Father-in-law": "Uban miji",
    "Friend": "Aboki",
    "Grandfather": "Kaka",
    "Grandmother": "Kaka (na miji)",
    "Husband": "Miji",
    "Mother": "Uwa",
    "Mother-in-law": "Uwar miji",
    "Nephew": "Zankwabo",
    "Niece": "Zankwaba",
    "Self (i.e. the sender, himself)": "Kai (shi ke nan mai aikawa)",
    "Sister": "Kanne",
    "Sister-in-law": "Kanuwa",
    "Son": "Da",
    "Uncle": "Kawu",
    "Wife": "Mata",
    "Others not listed": "Sauran wanda ba a jera ba",
    "Merchant": "Ɗan kasuwa",
    "No relationship": "Babu dangantaka",
    "Add Funds": "Ƙara Kuɗi",
    "Enter the Amount": "Shigar da Adadin:",
    "Fees": "Kudade:",
    "You Will Get": "Za ku karɓa:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Darajar ma'amala yakamata ta kasance tsakanin [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Adadi mara inganci: Matakin tabbatar da shaida na yanzu yana iyakance adadin da za ku iya ƙara. Da fatan za a haɓaka tabbatar da shaida don cin gajiyar iyakokin mafi girma.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Farashin kuɗi yana canzawa akai-akai bisa yanayin kasuwa. Da fatan za a koma zuwa farashin shafin tabbatar da oda azaman ƙarshe.",
    "Next": "Na gaba",
    "Upgrade Identity Verification": "Haɓaka Tabbatar da Shaida",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Nemi kuɗi daga kowa, ko'ina, gami da kan dandamalin sadarwar zamantakewa. Tare da InstaPay, buƙatun biyan kuɗin ku nan take ana iya samun su da zarar an karɓa kuma ana tura kuɗin zuwa Asusun Karɓarku. Sauƙaƙe ma'amalolinku kuma ku sarrafa biyan kuɗi cikin sauƙi da aminci.",
    "You’ve been logged out.": "An fitar da ku.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Domin tsarinku, an fitar da ku bayan mintuna 15 na rashin aiki. Da fatan za a sake shiga idan kuna son amfani da InstaPay.",
    "LOGIN AGAIN": "SAKE SHIGA",
    "Brother": "ɗan'uwa",
    "Current Password": "Kalmar sirri na yanzu",
    "The new password cannot be the same as the old one": "Sabon kalmar sirri ba zai zama kamar tsohuwar ba.",
    "Minimum password length: [X] characters ": "Mafi ƙarancin tsawon kalmar sirri: [X] haruffa",
    "Include at least one uppercase and one lowercase letter.": "Ka haɗa da aƙalla babban harafi da ƙaramin harafi.",
    "Nominee Information": "Bayanin Wanda aka Zaba",
    "Verification Code Preferences": "Zaɓuɓɓukan Lambar Tabbatarwa",
    "How Others Can Find You": "Yadda Wasu Zasu Iya Neman Ku",
    "My Social Network Accounts": "Asusun Sadarwar Zamani Na",
    "Login Activity": "Ayyukan Shiga",
    "Receiving Accounts": "Asusun Karɓa",
    "Change Your Password To Continue": "Canja Kalmar Sirrinka Don Ci gaba",
    "Continue with": "Ci gaba da",
    "Back to Login": "Koma zuwa Shiga",
    "Send OTP": "Aika OTP",
    "A Verification code has been sent to your email and number.": "An aika da lambar tabbatarwa zuwa imel ɗinku da lambar ku.",
    "Resend available in": "Akwai sake aikawa a cikin",
    "Create a strong Password": "Ƙirƙiri M kalmar sirri mai ƙarfi",
    "Password must:": "Kalmar sirri dole:",
    "Be between 9 and 64 characters": "Kasance tsakanin haruffa 9 zuwa 64",
    "Include at least two of the following:": "Haɗa aƙalla guda biyu daga cikin waɗannan:",
    "Lowercase Character": "Harafi ƙarami",
    "Special Character": "Harafi na Musamman",
    "Password Updated Successfully!": "An Sabunta Kalmar Sirri da Nasara!",
    "Click below to login": "Danna ƙasa don shiga",

    "Send Money": "Aika Kudi",
    "Enter the amount": null,
    "Recent Transfer": "Sabon Canja wurin",
    "See all": "Duba duk",
    "Send again": "Aika sake",
    "You Send": "Kana Aika",
    "They Receive": "Suna Karɓa",
    "Receive Method": "Hanyar Karɓa",
    "Select method": "Zaɓi hanyar",
    "Next": "Na gaba",
    "Trouble sending money?": "Matsala wajen aika kudi?",
    "Where do you want to send money ?": "Ina kake son aika kudi?",
    "Search ": "Bincika",
    "Confirm": "Tabbatar",
    "Bank Deposit": "Ajiya na Banki",
    "Cash Pick-up": "Karɓar Kuɗi",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Katin Banki",
    "Crypto Wallet": "Wallet na Crypto",
    "What is the receiver’s Mobile Wallet?": "Menene Wallet na wayar mai karɓa?",
    "Transfer fees": "Kuɗin canja wuri",
    "Total to pay": "Jimlar biyan kuɗi",
    "Choose the beneficiary!": "Zaɓi mai amfana!",
    "Country": "Ƙasar",
    "Receive method": "Hanyar karɓa",
    "You send": "Kana aika",
    "change": "canza",
    "edit": "ƙara sabo",
    "add new": "Masu Amfana",
    "Beneficiaries": "Sarrafa Masu Amfana",
    "Manage Beneficiaries": "Bincika",
    "Search": "Na gaba",
    "CASH PICK-UP": "KARƁAR KUDI",
    "Where will your receiver collect the money": "Inda mai karɓa zai karɓi kuɗi",
    "Cash is available to collect within minutes from any branch of [name] ": "Ana samun kuɗi a cikin mintuna daga kowanne reshe na [suna]",
    "BANK ACCOUNT": "ASUSUN BANKI",
    "Transfer to recipient’s bank account": "Canja wuri zuwa asusun bankin mai karɓa",
    "BANK CARD": "KATIN BANKI",
    "Fast transfer directly to recipient’s bank card": "Canja wuri kai tsaye zuwa katin bankin mai karɓa",
    "CRYPTO WALLET": "WALLET NA CRYPTO",
    "Fast transfer directly to a crypto wallet": "Canja wuri kai tsaye zuwa Wallet na crypto",
    "Review transfer": "Duba canja wuri",
    "Receiver's Details": "Bayanin mai karɓa",
    "Edit": "Gyara",
    "Name": "Suna",
    "Reason for sending": "Dalilin aika",
    "Sending": "Aika",
    "Receiver gets": "Mai karɓa ya karɓa",
    "Exchange rate": "Kudin canjin kuɗi",
    "Estimated time": "Lokacin da aka kiyasta",
    "Select Payment Type": "Zaɓi Nau'in Biyan Kuɗi",
    "Credit cards": "Katinan kuɗi",
    "Domestic cards": "Katinan cikin gida",
    "Mobile Wallets": "Wallet na wayar hannu",
    "InstaPay Wallet": "Wallet na InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KATINAN KUƊI",
    "Credit Card": "Katinan kuɗi",
    "Select card": "Zaɓi katin",
    "Card Number": "Lambar Katin",
    "Enter the 16-digit card number on the card": "Shigar da lambar katin ɗin 16",
    "Expiry Date": "Ranar ƙarewa",
    "Enter the expiration date of the card": "Shigar da ranar ƙarewar katin",
    "CVV Number": "Lambar CVV",
    "Enter the 3 or 4 digit number on the card": "Shigar da lambar 3 ko 4 da ke jikin katin",
    "INSTAPAY WALLET": "WALLET NA INSTAPAY",
    "Select Wallet": "Zaɓi Wallet",
    "My Wallet": "Wallet na",
    "Conversion": "Canji",
    "Convert": "Canja",
    "Your payment is confirmed": "An tabbatar da biyan kuɗin ku",
    "Thanks for using InstaPay": "Na gode da amfani da InstaPay",
    "Status": "Matsayi",
    "Share": "Raba",
    "Transfer created": "An ƙirƙiri canja wuri",
    "Payment received": "An karɓi biyan kuɗi",
    "Payment processed": "An sarrafa biyan kuɗi",
    "Transfer successful": "Canja wurin ya yi nasara",
    "It may take few hours for the funds to appear in [user name] account": "Zai iya ɗaukar sa'o'i kafin kuɗin ya bayyana a cikin asusun [sunan mai amfani]",
    "Transfer failed": "Canja wurin ya gaza",
    "Transaction Details": "Bayanin mu'amala",
    "Need help?": "Kuna buƙatar taimako?",
    "Service Details": "Cikakkun Bayanai na Sabis",
    "Funds Deposit Speed": "Samun Kuɗi",
    "Cut-off Time": "Lokacin Rufe",
    "Bank holiday list": "Jerin Ranakun Hutu na Banki",
    "Threshold limits": "Iyakokin Ma'amala",
    "null": null,
    "Instant, Real Time": "Nan take, ainihin lokaci",
    "Same day, T+1": "Ranar da aka yi, T+1",
    "Non-instant, T+1": "Ba nan take ba, T+1",
    "Instant Payment, Real Time": "Biyan Kudi Nan take, ainihin lokaci",
    "Transaction processed before 3:00 PM Local time": "An sarrafa ma'amala kafin ƙarfe 3:00 na rana lokaci na gida",
    "Delivered the same day": "An kawo a rana ɗaya",
    "Transactions processed post 3:00 PM": "Ma'amaloli da aka sarrafa bayan ƙarfe 3:00 na rana",
    "T+1 business days": "T+1 kwanakin kasuwanci",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Za a ba da kuɗin biyan kuɗi da aka yi a ranakun mako kafin ƙarfe 3:00 na rana a asusun bankin da ke karɓar kuɗin kafin ƙarfe 12:00 na dare a rana ɗaya.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Za a ba da kuɗin biyan kuɗi da aka yi a ranakun mako bayan ƙarfe 3:00 na rana kafin ƙarfe 12:00 na dare a ranar da ke biyo bayan ta.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Za a ba da kuɗin biyan kuɗi da aka yi a ranar Asabar, Lahadi ko ranar hutu a asusun kafin ƙarfe 12:00 na dare na ranar kasuwanci ta farko mai zuwa.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Lokaci na Gida",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Mafi ƙanƙanta adadin ma'amala:",
    "Maximum per transaction limit:": "Mafi girma adadin ma'amala:",
    "Daily threshold limit:": "Iyakar yau da kullun:",
    "Weekly threshold limit:": "Iyakar mako-mako:",
    "Monthly threshold limit:": "Iyakar wata-wata:",
    "Yearly threshold limit:": "Iyakar shekara-shekara:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Asusun Asali (Ba a Tabbatar ba)",
    "Identity Verified": "An Tabbatar da Shaidar",

    "Good Evening!": "",
    "Total Balance": "",
    "Quick Transfer": "",
    "No transactions": "",
    "Blocked balance": "",
    "Available balance": "",
    "Insufficient Balance in PKR": "",
    "Chat with us": "",
    "Add Currency": "",
    "Education": "",
    "Divers": "",
    "Family support": "",
    "Others": "",
    "Transport": "",
    "schedule payment": "",
    "subscription payment": "",
    "No payment": "",
    "No Beneficiaries": "",
    "Code Postal": "",
    "Additional Information": "",
    "Gender": "",
    "Date Of Birth": "",
    "Nationality": "",
    "Occupation": "",
    "ID Type": "",
    "ID Number": "",
    "Select Beneficiary Payment Channels": "",
    "wallet crypto": "",
    "Add another wallet": "",
    "Enterprise": "",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "",
    "Joined At": "",
    "Profile Link": "",
    "comission": "",
    "my withdrawls": "",
    "My Referrals": "",
    "search quotation": "",
    "no quotation": "",
    "actions": "",
    "search request": "",
    "no pending items ": "",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "",
    "Description": "",
    "save": "",
    "Share your Insta-Pay unique payment link": "",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "",
    "No Portfolio Items": "",
    "Add New Portfolio Item": "",
    "Title": "",
    "drag/drop or upload a file(photo/video/pdf)": "",
    "discard": "",
    "save and post ": "",
    "My Payment Address": "",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "",
    "Personal Account": "",
    "spent in total": "",
    "not connected": "",
    "more": "",
    "less": "",
    "profile reports": "",
    "All notifications are in English langauge": "",
    "timezone": "",
    "nominee information": "",
    "full name": "",
    "Relation with Nominee": "",
    "source": "",
    "Google Authenticator": "",
    "notifications": "",
    "login": "",
    "View Identity Verification Guide": "",
    "verified": "",
    "select ": "",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "",
    "sessions": "",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "",
    "Select the receiving channel below and setup the details.": "",
    "Please Select A Payer": "",
    "Number": "",
    "Account Holder Name": "",
    "Province": "",
    "postal": "",
    "delete channel": "",
    "set as default": "",
    "edit": "",
    "all channels are in english language in recieving account page": "",
    "select file type": "",
    "download data": "",
    "INTERNATIONAL TRANSFER": "",
    "enter the amount": "",
    "they recieve ": "",
    "trouble sending money": "",
    "select destination country": "",
    "bank transfer": "",
    "recieving method": "",
    "payer": "",
    "The fee and the received amount may vary depending on the chosen Payment Method": "",
    "search ": "",
    "show more": "",
    "select remark reasons": "",
    "credit and debit card": "",
    "Transfer Fees": "",
    "Estimated Time": "",
    "within minutes": "",
    "total ": "",
    "OTP verifications code": "",
    "verify using sms/email": "",
    "sms/email verification code": "",
    "verify using authenticator": "",
    "WALLET TO WALLET TRANSFER": "",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "",
    "search": "",
    "lookup": "",
    "Recipient Not on InstaPay?": "",
    "invite them": "",
    "Please follow these examples": "",
    "Wallet ID: GY68J782": "",
    "InstaPay/Instagram Username": "",
    "Email: user@email.com": "",
    "With Country code": "",
    "invite friend": "",
    "invite via phone number": "",
    "invite via email address": "",
    "next": "",
    "back": "",
    "Personalized Message": "",
    "available currency": "",
    "attach files": "",
    "total amount": "",
    "instant payment": "",
    "starting from": "",
    "recurring cycle": "",
    "until I stop": "",
    "start from": "",
    "cycle": "",
    "total": "",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "",
    "schedule to": "",
    "schedule at": "",
    "Scheduled Date": "",
    "Scheduled time": "",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "",
    "QR CODE PAYMENT ": "",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "",
    "PYAYMENT REQUEST": "",
    "choose the beneficiary": "",
    "trouble requesting money": "",
    "Standard Request": "",
    "Subscription Request": "",
    "Schedule Request": "",
    "Security Request": "",
    "Instalment Request": "",
    "Split Request": "",
    "You recieved": "",
    "is this your location?": "",
    "yes": "",
    "Authenticator Code": "",
    "Enter a code shown in the app to make sure everything works fine.": "",
    "SEND A QUOTE": "",
    "Trouble Quoting Money?": "",
    "Please follow these examples:": "",
    "Full Name: Muhammad Imtiaz": "",
    "InstaPay/Intagram Username: instauser": "",
    "Phone Number: 44795396600 (With Country Code)": "",
    "SEND TOP-UP AND E-SIM": "",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "",
    "MOBILE AIRTIME AND E-SIM": "",
    "Select the country where you want to top-up a mobile phone!": "",
    "Other Operator": "",
    "airtime": "",
    "bundle": "",
    "data": "",
    "Enter custom amount": "",
    "Credit & Debit Card": "",
    "Bank Transfer": "",
    "Mobile Wallet": "",
    "InstaPay Wallet": "",
    "PayPal": "",
    "add card payment": "",
    "save details to use next time.": "",
    "Recipient Mobile Number": "",
    "Amount": "",
    "Service": "",
    "Destination": "",
    "Provider": "",
    "Airtime": "",
    "Pakistan": "",
    "Telenor Pakistan": "",
    "Within minutes": "",
    "top-up summary": "",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "",
    "Amount in USD": "",
    "Send Top-up": "",
    "available sunservices": "",
    "login with email": "",
    "Already Have an Account?": "",
    "profile picture": "",
    "add photo/logo": "",
    "first name": "",
    "enter your first name": "",
    "last name": "",
    "enter your last name": "",
    "email(optional)": "",
    "enter your email address": "",
    "password must": "",
    "It will be autofill, if you're signing up with invitation link": "",
    "change number": "",
    "signup error": "",
    "Something went wrong while sending sms!": "",
    "Errors": "",
    "Generic error": "",
    "Trust Payments API requires the 'requests' library": "",
    "Send error": "",
    "Receive error": "",
    "Invalid credentials provided": "",
    "An issue occurred whilst trying to connect to Trust Payments servers": "",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "",
    "Unknown error. If this persists please contact Trust Payments": "",
    "Incorrect usage of the Trust Payments API": "",
    "Invalid card details": "",
    "Invalid date": "",
    "Invalid date/time": "",
    "Invalid details": "",
    "Card number does not match card type": "",
    "Malformed XML": "",
    "XML does not match schema": "",
    "Invalid file format": "",
    "Empty file contents": "",
    "Invalid file contents": "",
    "Malformed JSON": "",
    "StApi Error": "",
    "Invalid fields specified in request": "",
    "Missing parent": "",
    "Refund requires settled parent or parent that's due to settle today": "",
    "Refund requires authorisation parent": "",
    "Refund amount too great": "",
    "No acquirer specified": "",
    "Repeat amount too great": "",
    "Split amount too great": "",
    "Cannot refund a declined transaction": "",
    "Refund requires a settled parent": "",
    "Reversal requires a cancelled auth parent": "",
    "Cannot override amount in child transaction": "",
    "Cannot override currency in child transaction": "",
    "Subscription requires RECUR account": "",
    "Subscription requires successful parent": "",
    "Risk Decisions must have AUTH as parent": "",
    "Chargebacks must have AUTH/REFUND as parent": "",
    "Refund amount less than Minimum allowed": "",
    "Refund requires paypaltransactionid": "",
    "Invalid split transaction": "",
    "Cannot reverse AUTH processed more than 48 hours ago": "",
    "Reversal requires acquirerreferencedata": "",
    "Cannot reverse AUTH processed by a different acquirer": "",
    "Payment type does not support repeats": "",
    "Reversal missing required data": "",
    "Missing token": "",
    "Subscription with an accountcheck parent not supported on current acquirer": "",
    "Subscription cannot be used as a parent": "",
    "Invalid parent": "",
    "Payment type does not support refunds": "",
    "Invalid incremental transaction": "",
    "Partial reversals not supported": "",
    "THREEDQUERY parent/child must have the same payment type as the child": "",
    "Payment type does not support card scheme updates": "",
    "Cannot reverse AUTH at this time, please try again": "",
    "Cannot determine token": "",
    "Service Temporarily Disabled": "",
    "Login first request": "",
    "Invalid username/password": "",
    "Invalid session": "",
    "Session has expired": "",
    "Password expired": "",
    "Password has been previously used": "",
    "MyST user account has been locked": "",
    "New password does not match confirmed password": "",
    "Incorrect current password": "",
    "Invalid selection": "",
    "User already exists": "",
    "No transaction found": "",
    "Invalid selected transactions": "",
    "Data supplied has not been saved": "",
    "Invalid request type": "",
    "Missing request type, at least one request type must be selected": "",
    "Invalid payment type": "",
    "Missing payment type, at least one payment type must be selected": "",
    "Invalid error code": "",
    "Missing error code, at least one error code must be selected": "",
    "Invalid filter description": "",
    "Invalid destination description": "",
    "Invalid notification type": "",
    "Invalid destination": "",
    "Invalid field selected": "",
    "Invalid email from address": "",
    "Invalid email subject": "",
    "Invalid email type": "",
    "Unable to process request": "",
    "No file selected for upload": "",
    "Invalid file size": "",
    "Invalid filename": "",
    "Invalid extension": "",
    "User requires at least one site reference": "",
    "Only ST-level users can have '*' access": "",
    "Request failed": "",
    "Invalid File Contents": "",
    "Maximum number of files uploaded": "",
    "Insufficient gateway access privileges": "",
    "Maximum file size limit reached": "",
    "Username(s) must be a valid user(s)": "",
    "Site reference(s) must be a valid site(s)": "",
    "Unable to send email, please verify the details and try again": "",
    "Negative already exists": "",
    "Cannot delete a search owned by another user": "",
    "Invalid search": "",
    "Cannot delete the specified search, the search name cannot be found": "",
    "Search parameter is too short": "",
    "Duplicate custom fields defined": "",
    "Cannot allocate selected users, insufficient privileges": "",
    "Allocated users have access to additional sites": "",
    "Allocated users have access to additional users": "",
    "User with current role cannot be allocated users": "",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "",
    "User requires at least one site reference or site group": "",
    "Allocated users have access to additional site groups": "",
    "No statement found": "",
    "Data supplied has not been updated in MobilePay 3rd-party service": "",
    "Bypass": "",
    "Insufficient access privileges": "",
    "Coding error": "",
    "Insufficient privileges": "",
    "Invalid request": "",
    "Invalid field": "",
    "Unknown site": "",
    "Banned card": "",
    "XML element parse error": "",
    "Maestro must use SecureCode": "",
    "Multiple email addresses must be separated with , or ;": "",
    "Invalid site reference for alias": "",
    "Invalid version number": "",
    "Unknown user": "",
    "Cannot determine account": "",
    "JSON element parse error": "",
    "Wallet type configuration error": "",
    "Wallet type not supported on this request": "",
    "The card number you have provided is incorrect, please verify your details and try again": "",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "",
    "The expiry date you have provided is incorrect, please verify your details and try again": "",
    "The expiry month you have provided is incorrect, please verify your details and try again": "",
    "The expiry year you have provided is incorrect, please verify your details and try again": "",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "",
    "The issue number you have provided is incorrect, please verify your details and try again": "",
    "The payment type you have provided is incorrect, please verify your details and try again": "",
    "Unable to process your payment, please contact the website": "",
    "There are errors with these fields: {0}": "",
    "No account found": "",
    "Refund cannot be processed": "",
    "Transaction de-activated": "",
    "Socket receive error": "",
    "Socket connection error": "",
    "Socket closed": "",
    "Invalid data received": "",
    "Invalid SQL": "",
    "Timeout": "",
    "Invalid acquirer": "",
    "Unable to connect to acquirer": "",
    "Invalid response from acquirer": "",
    "No available transport": "",
    "File size too large": "",
    "Socket send error": "",
    "Communication error": "",
    "Proxy error": "",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "",
    "Wrong number of emails": "",
    "Bank System Error": "",
    "Wrong number of transactions": "",
    "Invalid transport configuration": "",
    "No valid updates specified": "",
    "Transaction reference not found": "",
    "Settlebaseamount too large": "",
    "Transaction not updatable": "",
    "No searchable filter specified": "",
    "Timeout Error": "",
    "3-D Secure Transport Error": "",
    "Unauthenticated": "",
    "Site Suspended": "",
    "No updates performed": "",
    "Invalid Request": "",
    "Invalid Response": "",
    "Invalid Acquirer": "",
    "Invalid account data": "",
    "Missing": "",
    "Payment Error": "",
    "Invalid acquirer for 3-D Secure": "",
    "Invalid payment type for 3-D Secure": "",
    "Invalid updates specified": "",
    "Manual investigation required": "",
    "Invalid headers": "",
    "Max fraudscore exceeded": "",
    "Invalid filters": "",
    "Merchant System Error": "",
    "Your payment is being processed. Please wait...": "",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "",
    "Acquirer missing original transaction data": "",
    "Insufficient funds": "",
    "Unable to process due to scheme restrictions": "",
    "Failed Screening": "",
    "Unable to process due to restrictions": "",
    "Invalid process": "",
    "Risk Referral": "",
    "Name Pick required": "",
    "Address Pick required": "",
    "IP not in range": "",
    "Invalid button configuration": "",
    "Unrecognised response from acquirer": "",
    "Decline": "",
    "Uncertain result": "",
    "Soft Decline": "",
    "Refer to Issuer": "",
    "Request is queued please check the transaction later for the status": "",
    "Generic Retry": "",
    "Soft Decline retry": "",
    "There has been a problem with your payment, please verify your details and try again": "",
    "Unknown error": "",

    "Login": "Shiga",
    "Enter Your Email": "Shigar da imel ɗinku",
    "Login With Phone Number": "Shiga tare da lambar waya",
    "Verify Using Sms/Email": "Tabbatar da amfani da SMS/Email",
    "Resend Code In 100 Seconds": "Aika lambar sake cikin daƙiƙa 100",
    "Verify": "Tabbatar",
    "Verifying": "Tabbatarwa na ci gaba",
    "Last Week": "Mako na ƙarshe",
    "Older Transaction": "Ma'amala tsohuwa",
    "Total": "Jimla",
    "Conversion Confirmed": "Canji an tabbatar",
    "User Account Limit": "Iyakar asusun mai amfani",
    "Monthly Balance Limit": "Iyakar ajiya na wata-wata",
    "Total Limit": "Jimlar iyaka",
    "Used": "Amfani",
    "Remaining": "Mafi Kusa",
    "Allowed Minimum Topup Amount": "Adadin ƙananan lodin da aka yarda da shi",
    "Allowed Maximum Topup Amount": "Adadin mafi girma na lodin da aka yarda da shi",
    "Incoming Limit": "Iyakar shigarwa",
    "Daily limit": "Iyakar Kullum",
    "Monthly limit": "Iyakar Watan",
    "Yearly limit": "Iyakar Shekara",
    "Sending Limit": "Iyakar aika",
    "Allowed Number Of Transactions": "Yawan mu'amaloli da aka yarda",
    "Total Amount": "Jimlar adadi",
    "Amount To Send": "Kudin da za a aika",
    "Destination Amount": "Kudin da aka nufa",
    "Note": "Lura",
    "Revised Amount": "Kudin da aka gyara",
    "Save": "Ajiye",
    "Date": "Ranar",
    "Action": "Aiki",
    "Payment Scheduled At": "Lokacin Biyan Kuɗi",
    "Transaction Fee": "Kudin Harka",
    "Number Of Cycle": "Yawan Zagaye",
    "Subscription Starting Date": "Ranar Fara Rajista",
    "Until": "Zuwa",
    "Next Transaction": "Harkar Gaba",
    "Pay Now": "Biya Yanzu",
    "Transaction Method": "Hanyar Harka",
    "Mobile Money Provider": "Mai Bayar da Kuɗin Wayar Hannu",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number": "Lambar Wallet",
    "Crypto Currency": "Kuɗin Digital",
    "Wallet Address": "Adireshin Wallet",
    "Select Document Type": "Zaɓi Nau'in Takarda",
    "Edit Document Number": "Gyara Lambar Takarda",
    "My Withdrawals": "Fitarwar Ku",
    "Bargainable": "Zai Iya Tattaunawa",
    "Description": "Bayani",
    "Attached Files": "Fayilolin da aka haɗa",
    "Sent": "An Aika",
    "Accepted": "An Karɓa",
    "Decline": "An ƙi",
    "Pending": "A cikin jiran aiki",
    "Overall Rating": "Jimlar Kimantawa",
    "No Review": "Babu Bita",
    "0 Rating": "Kimantawa 0",
    "Transaction Map": "Taswirar Ma'amala",
    "Send Money": "Aika Kudi",
    "Sending Method": "Hanyar Aiko",
    "Trouble Sending Money": "Matsala a Aika Kuɗi",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Kudin da aka karɓa da kuma adadin na iya canzawa bisa ga hanyar biyan kuɗi da aka zaɓa.",
    "Select Method": "Zaɓi Hanya",
    "Completed": "An Kammala",
    "How Was Your Experience?": "Yaya Kasancewar ƙwarewar ku?",
    "Leave A Review": "Bar Bita",
    "Review History": "Tarihin Bita",
    "Write A Review": "Rubuta Bita",
    "Send And Post": "Aika da wallafa",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Canjin Crypto zuwa Fiat yana da ƙarfi kuma mai aminci wanda ke canza cryptocurrencies zuwa kuɗin fiat ba tare da matsala ba. Yana biyan bukatun masu amfani daban-daban, yana ba da hanyoyin biyan kuɗi da dama waɗanda aka keɓe don dacewa da abubuwan da ake so na kowane mutum da wurare.\"",
    "Review Created": "Bita an ƙirƙira",
    "Transactions": "Ma'amaloli",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Ja/Dora ko loda fayil (Hotuna/Video/PDF)",
    "Max Size 1GB": "Mafi girman girman 1GB",
    "Title Description": "Bayanin taken",
    "Add New": "Ƙara Sabbi",
    "Discard": "Watsi",
    "Save And Post": "Ajiye da Buga",
    "Personal Account": "Asusun Kai",
    "Conversion": "Canji",
    "Spent In Total": "Jimlar da aka kashe",
    "International": "Kasashen Duniya",
    "Recharge": "Caji",
    "Top Up Wallet To Wallet": "Cika Wallet zuwa Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Ba a haɗa ba",
    "More": "Karin",
    "Less": "Ƙaranci",
    "Profile Reports": "Rahotannin Bayanai",
    "Timezone": "Lokacin Zama",
    "Cancel": "Soke",
    "Full Name": "Cikakken Suna",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Takardun Kari",
    "Profile Complete": "Cikakken Bayani",
    "Verified": "An Tabbatar",
    "Bank Account": "Asusun Banki",
    "Mobile Wallet": "Wallet na Waya",
    "Cash Pickup": "Karɓar Kuɗi",
    "Add": "Ƙara",
    "Configured": "An Shirya",
    "Email": "Imel",
    "Download Data": "Zazzage Bayanai",
    "Enter The Amount": "Shigar da Adadi",
    "They Receive": "Suna Karɓa",
    "Where Do You Want To Send Money": "Ina kake son Aika Kuɗi",
    "Bank Transfer": "Canja Wuri na Banki",
    "Receiving Method": "Hanyar Karɓa",
    "Payer": "Mai Biyan Kuɗi",
    "Minimum Amount": "Mafi ƙarancin Adadi",
    "Maximum Amount": "Mafi girman adadi",
    "Search": "Neman",
    "Show More": "Nuna ƙari",
    "Select Remark / Reasons (All)": "Zaɓi Sharhi / Dalilai (Duk)",
    "Attach Files": "Haɗa Fayiloli",
    "Holiday List": "Jerin Hutu",
    "No Data To Show": "Babu Bayanai Don Nuna",
    "Threshold Limits": "Iyakoki na Ƙa'idodi",
    "Amount Limits": "Iyakokin Adadi",
    "OTP Verification Code": "OTP Lambar Tabbatarwa",
    "Verify Using Authenticator": "Tabbatar da Amfani da Authenticator",
    "Sms/Email Verification Code": "SMS/Email Lambar Tabbatarwa",
    "Invite Them": "Kira Su",
    "Lookup": "Duba",
    "Invite Friend": "Kira Aboki",
    "Invite Via Phone Number": "Kira ta Lambar Waya",
    "Invite Via Email Address": "Kira ta Adireshin Imel",
    "Next": "Na gaba",
    "Back": "Komawa",
    "Invite Via Email": "Kira ta Imel",
    "Available Currency": "Kuɗin Da Ake da shi",
    "Add Remarks And Comments": "Ƙara Sharhi da Ra'ayoyi",
    "Recipient Details": "Cikakkun Bayanin Mai Karɓa",
    "Go Back": "Komawa",
    "Trouble Requesting Money": "Matsala wajen Neman Kuɗi",
    "Instant": "Nan da nan",
    "Subscription": "Rijista",
    "Schedule": "Jadawali",
    "Choose Request Type": "Zaɓi Nau'in Neman",
    "Mobile Airtime": "Lokacin Waya",
    "Incorrect Phone Number": "Lambar Waya Mara Daidai",
    "Make Sure You Have Entered A Correct Phone Number.": "Tabbatar cewa ka shigar da lambar waya daidai.",
    "Close": "Rufe",
    "Operator": "Mai Aiki",
    "Recipient Mobile Number": "Lambar Waya Mai Karɓa",
    "Amount": "Adadi",
    "Service": "Sabis",
    "Destination": "Wuri",
    "Top Up Summary": "Taƙaitaccen Cika",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Iyakance Adadin Aiki na Ranar ya wuce",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Aika da cikakken ƙididdiga na farashi ga abokin ciniki ko mai siye. Nuna sabis ɗinku, kayayyakin ku, ko tayin ku cikin kwararru tare da ƙididdiga na musamman.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Biyan kuɗi da aka yi a cikin kwanakin aiki kafin karfe 3:00 PM za a loda zuwa asusun bankin da ya karɓa kafin ƙarfe 12 na dare na ranar.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Saita asusun karɓar ku a cikin ƙasashe har zuwa uku kuma a fitar da kuɗi nan da nan daga buƙatun biyan kuɗi da aka amince da su zuwa hanyoyin biyan kuɗi da aka kunna.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom yana da hakkin neman duk wani ƙarin takardu (Ƙarin Duba Mai Zurfi - EDD) ko ƙarin bayanai da ake buƙata don gudanar da ingantattun bincike, bisa ga wajiban doka.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Dole ne ya zama takardar shaidar gwamnati mai dauke da hoto. Duk takardun shaida dole ne a rubuta su cikin Haruffan Latin. Idan ba haka ba, ana buƙatar fassarar da aka tabbatar.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Nuna aikinka, faɗaɗa hanyoyin samun. 'Portfolio Na' shine zanen dijital naka a Instapay. Loda da nuna nau'ikan abubuwa daban-daban - daga hotuna masu jan hankali da bidiyo zuwa PDFs masu bayanai. Ko kai mai zane, mai zaman kansa ko mai kasuwanci, wannan fasalin yana ba ka damar nuna aikinka ko ayyukanka cikin hanya mai jan hankali ta fuskar kallo.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Raba lambar QR ɗinka ko haɗin biyan kuɗi don samun biyan kuɗi nan da nan a cikin Insta Wallet ɗinka",
}