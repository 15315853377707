import { createSlice } from "@reduxjs/toolkit";

const beneficiariesSlice = createSlice({
    name: "beneficiaries",
    initialState: [
        {
            id: 1,
            isVerified: true,
            avatar: "https://img.freepik.com/free-icon/user_318-159711.jpg",
            name: "Muhammad Imtiaz",
            country: "Pakistan",
            address: "DHA Phase 2",
            city: "Karachi",
            email: "imtiaz123@xyz.com",
            mobile_number: "+923070186322",
            relation: "Family Support",
            account_type: ["Bank", "Mobile", "Card", "Crypto"],
            account_holder_name: "Muhammad Imtiaz",
            bank_name: "Habib Bank Limited",
            branch_name: "Chandrigarh Road Karachi",
            bank_account_number: "2222 2222 33333 5678",
            mobile_wallet_number: "03070186322",
            mobile_wallet_name: "Jazzcash",
            mobile_wallet_account_number: "03070186322",
            card_holder_one: "Muhammad Imtiaz Hussain",
            card_number_one: "2222 3333 4444 5555",
            card_expiry_one: "10/23",
            card_holder_two: "Imtiaz Hussain",
            card_number_two: "2345 5432 7326 2319",
            card_expiry_two: "12/25",
            card_holder_three: "Hussain",
            card_number_three: "1145 5566 6782 5246",
            card_expiry_three: "02/25",
            crypto_currency: "Bitcoin",
            crypto_address: "ABCN123908213DAIOW286D"
        },
        {
            id: 2,
            isVerified: true,
            avatar: "https://variety.com/wp-content/uploads/2023/03/John-Wick-3.jpg",
            name: "John Doe",
            country: "United States",
            address: "123 Main Street",
            city: "New York",
            email: "johndoe@example.com",
            mobile_number: "+1234567890",
            relation: "Friend",
            account_type: ["Bank", "Mobile", "Card"],
            account_holder_name: "John Doe",
            bank_name: "Example Bank",
            branch_name: "New York Branch",
            bank_account_number: "1234 5678 9012",
            mobile_wallet_number: "1234567890",
            mobile_wallet_name: "PayPal",
            mobile_wallet_account_number: "johndoe@example.com",
            card_holder_one: "John Doe",
            card_number_one: "1111 2222 3333 4444",
            card_expiry_one: "05/24",
            card_holder_two: "",
            card_number_two: "",
            card_expiry_two: "",
            card_holder_three: "",
            card_number_three: "",
            card_expiry_three: "",
            crypto_currency: "Ethereum",
            crypto_address: "0x9876ABCDEF0123456789"
        },
        {
            id: 3,
            isVerified: true,
            avatar: "https://img.freepik.com/free-icon/user_318-159711.jpg",
            name: "Jane Smith",
            country: "Canada",
            address: "456 Maple Avenue",
            city: "Toronto",
            email: "janesmith@example.com",
            mobile_number: "+9876543210",
            relation: "Colleague",
            account_type: ["Bank", "Mobile"],
            account_holder_name: "Jane Smith",
            bank_name: "Bank of Canada",
            branch_name: "Toronto Branch",
            bank_account_number: "9876 5432 1098",
            mobile_wallet_number: "9876543210",
            mobile_wallet_name: "Venmo",
            mobile_wallet_account_number: "janesmith@example.com",
            card_holder_one: "",
            card_number_one: "",
            card_expiry_one: "",
            card_holder_two: "",
            card_number_two: "",
            card_expiry_two: "",
            card_holder_three: "",
            card_number_three: "",
            card_expiry_three: "",
            crypto_currency: "",
            crypto_address: ""
        },
        {
            id: 4,
            isVerified: true,
            avatar: "https://img.freepik.com/free-icon/user_318-159711.jpg",
            name: "Alex Johnson",
            country: "Australia",
            address: "789 Oak Street",
            city: "Sydney",
            email: "alexjohnson@example.com",
            mobile_number: "+6123456789",
            relation: "Business Partner",
            account_type: ["Bank", "Card"],
            account_holder_name: "Alex Johnson",
            bank_name: "Australia Bank",
            branch_name: "Sydney Branch",
            bank_account_number: "5678 9012 3456",
            mobile_wallet_number: "",
            mobile_wallet_name: "",
            mobile_wallet_account_number: "",
            card_holder_one: "Alex Johnson",
            card_number_one: "4444 5555 6666 7777",
            card_expiry_one: "08/23",
            card_holder_two: "Alex J",
            card_number_two: "8765 4321 9876 5432",
            card_expiry_two: "11/25",
            card_holder_three: "",
            card_number_three: "",
            card_expiry_three: "",
            crypto_currency: "",
            crypto_address: ""
        },
        {
            id: 5,
            isVerified: true,
            avatar: "https://img.freepik.com/free-icon/user_318-159711.jpg",
            name: "Emily Johnson",
            country: "United Kingdom",
            address: "789 Park Lane",
            city: "London",
            email: "emilyjohnson@example.com",
            mobile_number: "+44123456789",
            relation: "Neighbor",
            account_type: ["Bank", "Mobile", "Card"],
            account_holder_name: "Emily Johnson",
            bank_name: "London Bank",
            branch_name: "London Branch",
            bank_account_number: "1234 5678 9012",
            mobile_wallet_number: "9876543210",
            mobile_wallet_name: "Revolut",
            mobile_wallet_account_number: "emilyjohnson@example.com",
            card_holder_one: "Emily Johnson",
            card_number_one: "1111 2222 3333 4444",
            card_expiry_one: "03/24",
            card_holder_two: "Emily J",
            card_number_two: "5678 9012 3456 7890",
            card_expiry_two: "06/26",
            card_holder_three: "",
            card_number_three: "",
            card_expiry_three: "",
            crypto_currency: "",
            crypto_address: ""
        },
        {
            id: 6,
            isVerified: true,
            avatar: "https://img.freepik.com/free-icon/user_318-159711.jpg",
            name: "Michael Brown",
            country: "United States",
            address: "567 Elm Street",
            city: "Los Angeles",
            email: "michaelbrown@example.com",
            mobile_number: "+19876543210",
            relation: "Co-worker",
            account_type: ["Bank"],
            account_holder_name: "Michael Brown",
            bank_name: "Bank of America",
            branch_name: "Los Angeles Branch",
            bank_account_number: "9876 5432 1098",
            mobile_wallet_number: "",
            mobile_wallet_name: "",
            mobile_wallet_account_number: "",
            card_holder_one: "",
            card_number_one: "",
            card_expiry_one: "",
            card_holder_two: "",
            card_number_two: "",
            card_expiry_two: "",
            card_holder_three: "",
            card_number_three: "",
            card_expiry_three: "",
            crypto_currency: "",
            crypto_address: ""
        }

    ],
    reducers: {
        deleteBeneficiary: (state, action) => {
            return state = state.filter(item => item.id !== action.payload)
        },
        updateBeneficiary: (state, action) => {
            return state.map(beneficiary => {
                if (beneficiary.id === action.payload.id) {
                    beneficiary = action.payload
                }
                return beneficiary;
            });
        }
    }
})

export default beneficiariesSlice.reducer
export const { deleteBeneficiary, updateBeneficiary } = beneficiariesSlice.actions